export const module_ = {
    object: 'Payment',
    name: 'ReliefFundPaymentValidation',
    tKey: 'Validation paiement',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            { path: 'reliedFundApplicationNumber', tKey: 'applicationNumber', width: 120 },
            { path: 'organizationName' },
            { path: 'object', initiallyNotVisible: true },
            { path: 'natureOfHelp', translate: true },
            { path: 'awardedAmount', width: 120, tooltip: true },
            { path: 'balance', tKey: 'Solde (€)', width: 120, tooltip: true },
            { path: 'amount', tKey: 'Mt proposé (€)', width: 120, tooltip: true },
            { path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], translate: true },
        ],
        form: [
            { path: 'reliedFundApplicationNumber', tKey: 'applicationNumber', editable: false},
            { path: 'organizationName', editable: false },
            { path: 'object', editable: false },
            { path: 'natureOfHelp', translate: true, editable: false },
            { path: 'awardedAmount', editable: false },
            { path: 'balance', tKey: 'Solde (€)', editable: false },
            { path: 'amount', tKey: 'Mt proposé (€)', editable: false },
            { path: 'comments', tKeyText: 'Suivi' },
            { path: 'account', hidden: true },
            { path: 'status', hidden: true },
            { path: 'buttons', hidden: true }
        ]
    },
    filters: ['byAcceptedFiles', 'byStatus']
}
