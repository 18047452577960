import React, {useState} from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import './QuillTextEditor.css'
import ValidationUI from "../ValidationUI";
import axios from 'axios'
import {toastr} from "react-redux-toastr";
import moment from "moment";

import ImageResize from 'quill-image-resize';
import {Trash, CornerDownRight, CornerUpRight, CornerLeftDown, CornerRightDown, XSquare, X, AlignLeft, AlignRight, AlignCenter, ChevronRight, Columns, Table, Image} from 'react-feather';
import ReactDOM from "react-dom";
import ImageContextMenu from "./quillTextEditorUtils/imageContextMenu";

const Quill = ReactQuill.Quill

const Font = Quill.import('formats/font')
Font.whitelist = ['', 'Arial', 'Georgia', 'Helvetica', 'Tahoma', 'Times', 'Trebuchet', 'Verdana']
Quill.register(Font, true)

const Embed = Quill.import('blots/embed');
class ImageBlot extends Embed {
    static create(value) {
        const node = super.create();

        // Create the image element
        const img = document.createElement('img');

        img.setAttribute('src', value.src);

        /*img.setAttribute('alt', value.alt || ''); // Support alt text
        if (value.style) {
            //img.setAttribute('style', value.style); // Apply any styles if provided
        }*/
        if (value.width) {
            img.setAttribute('width', value.width); // Apply any styles if provided
        }
        if (value.height) {
            img.setAttribute('height', value.height); // Apply any styles if provided
        }
        if (value.classList && Array.isArray(value.classList)) {
            img.classList.add(...value.classList); // Restore the classList
        }

        //container.appendChild(img);
        node.appendChild(img);

        return node;
    }

    static value(node) {
        // Find the img element within the container
        const img = node.querySelector('img');
        if (!img) return null;

        return {
            src: img.getAttribute('src'),
            style: img.getAttribute('style'),
            width: img.getAttribute('width'),
            height: img.getAttribute('height'),
            classList: Array.from(img.classList),
        };
    }

    attach() {
        super.attach();
        // Bind handleContextMenu to this instance if not already done
        if (!this._boundHandleContextMenu) {
            this._boundHandleContextMenu = this.handleContextMenu.bind(this);
        }

        // Find the image element inside the domNode
        const img = this.domNode.querySelector('img');

        if (img) {
            // Attach the context menu listener to the img element if not already attached
            if (!img._contextMenuAttached) {
                img.addEventListener('contextmenu', this._boundHandleContextMenu);
                img._contextMenuAttached = true; // Flag to avoid duplicate listeners
            }
        }

        // Ensure we don't add multiple document-level listeners
        if (!this._documentContextMenuListener) {
            this._documentContextMenuListener = (e) => {
                if (this.isEventOnImage(e)) {
                    this._boundHandleContextMenu(e);
                }
            };

            document.addEventListener('contextmenu', this._documentContextMenuListener, true);
        }
    }
    isEventOnImage(e) {
        const img = this.domNode.querySelector('img');
        const numberOfTargetChildren = e.target.children.length
        const expectedNumberOfTargetChildren = 5
        const targetFirstChildHTML = e.target.children[0]?.outerHTML
        const expectedTargetHTML = `<div style=\"position: absolute; height: 12px; width: 12px; background-color: white; border: 1px solid rgb(119, 119, 119); box-sizing: border-box; opacity: 0.8; cursor: nwse-resize; left: -6px; top: -6px;\"></div>`
        const clickedOnImage = numberOfTargetChildren === expectedNumberOfTargetChildren && targetFirstChildHTML === expectedTargetHTML
        return clickedOnImage
    }

    handleContextMenu(e) {
        e.preventDefault();
        e.stopPropagation();

        // Remove existing context menu
        const existingMenu = document.querySelector('.image-context-menu');
        if (existingMenu) {
            existingMenu.parentElement.remove();
        }

        const menuContainer = document.createElement('div');
        //const { adjustedX, adjustedY } = this.adjustMenuPosition(e.pageX, e.pageY);
        const { subMenuPosition, menuPosition } = this.adjustMenuPosition(e.pageX);

        // Create and render context menu
        ReactDOM.render(
            <ImageContextMenu
                x={menuPosition}
                y={e.pageY}
                onClose={() => {
                    if (document.body.contains(menuContainer)) {
                        document.body.removeChild(menuContainer);
                    }
                }}
                onAction={(action) => {
                    this.handleImageAction(action);
                    if (document.body.contains(menuContainer)) {
                        document.body.removeChild(menuContainer);
                    }
                }}
                subMenuPosition={subMenuPosition}
            />,
            menuContainer
        );

        document.body.appendChild(menuContainer);

        // Close menu when clicking outside
        const closeMenu = (e) => {
            if (!menuContainer.contains(e.target)) {
                if (document.body.contains(menuContainer)) {
                    document.body.removeChild(menuContainer);
                }
                document.removeEventListener('click', closeMenu);
            }
        };

        setTimeout(() => {
            document.addEventListener('click', closeMenu);
        }, 0);
    }

    adjustMenuPosition(x) {
        const menuWidth = 220; // Approximate menu width
        const subMenuWidth = menuWidth + 200

        const leftSpace = window.innerWidth - x;
        const menuPosition = leftSpace > menuWidth ? x : x - 210
        const subMenuPosition = leftSpace > subMenuWidth ? {left : '100%'} : {right: '100%'}

        return { subMenuPosition, menuPosition};
    }

    handleImageAction(action) {
        const node = this.domNode;
        let img

        const quillContainer = this.parent.parent.domNode.parentNode.children;
        const expectedTargetHTML = `<div style=\"position: absolute; height: 12px; width: 12px; background-color: white; border: 1px solid rgb(119, 119, 119); box-sizing: border-box; opacity: 0.8; cursor: nwse-resize; left: -6px; top: -6px;\"></div>`
        const imageResizeFrameDiv = Array.from(quillContainer).find(child => child.children.length === 5 && child.children[0].outerHTML === expectedTargetHTML)
        if (imageResizeFrameDiv) {
            // If resize frame exists, find the image it's associated with
            // The resize frame's position corresponds to the selected image
            const frameLeft = parseInt(imageResizeFrameDiv.style.left);
            const frameTop = parseInt(imageResizeFrameDiv.style.top);
            const allImages = Array.from(this.parent.parent.domNode.parentNode.querySelectorAll('img'));

            img = allImages
                .find(imgEl =>
                    imgEl &&
                    Math.abs(imgEl.offsetLeft - frameLeft) <= 1 &&
                    Math.abs(imgEl.offsetTop - frameTop) <= 1
                );
        } else {
            img = node.querySelector('img')
        }

        // Remove existing alignment classes
        const alignmentClasses = [
            'align-left', 'align-right', 'align-center',
            'align-left-with-text', 'align-right-with-text', 'align-center-with-text'
        ];

        if (img){
            img.classList.remove(...alignmentClasses);
            const parent = img.parentNode.parentNode.parentNode // img -> span -> custom-image -> p

            // Handle different actions
            const newParagraph = document.createElement('p');
            const textNodes = Array.from(parent.childNodes).filter(node => node.localName !== 'custom-image');
            const textNodesHtml = textNodes.map(node => {
                return node.nodeType === Node.TEXT_NODE ? node.textContent : node.outerHTML})
                .join('')
            newParagraph.innerHTML = textNodesHtml
            switch (action) {
                case 'alignLeft':
                case 'alignRight':
                case 'alignCenter':
                    parent.removeAttribute('contenteditable');
                    img.classList.add(`align-${action.replace('align', '').toLowerCase()}`);
                    break;
                case 'alignLeftWithText':
                    parent.setAttribute('contenteditable', 'false'); // Prevent Quill from editing the table as a whole
                    // Remove all text nodes
                    textNodes.forEach(textNode => {
                        parent.removeChild(textNode);
                    });
                    parent.insertAdjacentElement('afterend', newParagraph);
                    img.classList.add('align-left-with-text');
                    break;
                case 'alignRightWithText':
                    parent.setAttribute('contenteditable', 'false'); // Prevent Quill from editing the table as a whole
                    // Remove all text nodes
                    textNodes.forEach(textNode => {
                        parent.removeChild(textNode);
                    });
                    parent.insertAdjacentElement('afterend', newParagraph);
                    img.classList.add('align-right-with-text');
                    break;
                case 'alignCenterWithText' :
                    parent.removeAttribute('contenteditable');
                    break
                case 'removeImage':
                    node.remove()
                    if (imageResizeFrameDiv){ imageResizeFrameDiv.remove() }
                    break;
            }
            const leftOffset = img.offsetLeft + 'px'
            const topOffset = img.offsetTop + 'px'
            if (imageResizeFrameDiv && leftOffset){
                imageResizeFrameDiv.style.left = leftOffset;
            }
            if (imageResizeFrameDiv && topOffset){
                imageResizeFrameDiv.style.top = topOffset;
            }
        }
    }
}

ImageBlot.blotName = 'image';
ImageBlot.tagName = 'custom-image';
Quill.register(ImageBlot)

Quill.register('modules/imageResize', ImageResize);

class Editor extends React.Component {
    constructor (props) {
        super(props)
        this.quillRef = React.createRef()
        //this.state = {isTableSelectorOpen: false}
        this.handleChange = this.handleChange.bind(this)
        this.selectLocalImage = this.selectLocalImage.bind(this)
        this.saveToServer = this.saveToServer.bind(this)
        this.insertToEditor = this.insertToEditor.bind(this)
    }

    handleChange (html) {
        this.props.onChange(html)
    }

    selectLocalImage() {
        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        // Listen upload local image and save to server
        input.onchange = () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                this.saveToServer(file)
            } else {
                console.warn('You could only upload images.');
            }
        };
    }

    saveToServer(file) {
        const data = new FormData()

        const fileSize = file.size
        const chunkSize = 15 * 1024 * 1024

        let fileChunk = null, chunkNb = 0
        for (let start = 0; start < fileSize; start += chunkSize, chunkNb++) {
            if (start + chunkSize > fileSize) {
                fileChunk = file.slice(start, fileSize)
            }
            else {
                fileChunk = file.slice(start, start + chunkSize)
            }
            data.append('imageChunks', fileChunk, file.name)
        }

        if(this.props.user) {
            data.append('user', JSON.stringify(this.props.user))
        }
        data.append('fileSize', fileSize)
        data.append('chunkNb', chunkNb)

        const options = {
            headers: { 'Content-Type': 'multipart/form-data' }
        }

        axios.post('/image', data, options)
            .then(res => {
                const {name, date} = res.data
                const fullDate = moment(date).format('YYYY-MM-DD_HH-mm-ss')
                const url = `/image/${fullDate}_${name}`
                this.insertToEditor(url)
            })
            .catch(() => {
                toastr.error(this.props.t('errorUploading'))
            })
    }

    insertToEditor(url) {
        console.log('Quill img:', this.quillRef?.current?.getEditor());

        const editor = this.quillRef.current.getEditor()
        const range = editor.getSelection()

        const hostname = window.location.hostname
        const link = hostname === 'localhost'
            ?  `http://${hostname}:9003${url}`
            :  `https://${hostname}${url}`

        editor.insertEmbed(range.index, 'image', {
            src: link,
            classList: ['align-center'],
            //alignment: 'center-with-text', // Or dynamically set based on user input
            //style: 'max-width: 100%; height: auto; display: block;'
        });
    }

    static formats = [
        'header', 'font', 'size',
        'bold', 'italic', 'underline', 'strike', 'blockquote',
        'color', 'background', 'list', 'bullet', 'indent', 'align',
        'link',
        'image',
        //'table'
        //, 'video'
    ]

    render() {
        const { value, touched, error, path, required, t, readOnly, disabled, placeholder } = this.props

        const modules = {
            toolbar: {
                container: [
                    [{ 'header': '1'}, {'header': '2'}, { 'font': Font.whitelist }],
                    [{size: []}],
                    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                    [{ 'color': [] }, { 'background': [] }],
                    [{'list': 'ordered'}, {'list': 'bullet'},
                        {'indent': '-1'}, {'indent': '+1'}, { 'align': [] }],
                    ['link', 'image',
                        //    'table'
                    ],
                    ['clean'],
                ],
                handlers: {
                    image: this.selectLocalImage,
                    //table: this.handleInsertTable
                }
            },
            clipboard: {
                // toggle to add extra line breaks when pasting HTML:
                matchVisual: false,
            },
            imageResize: {
                modules: ['Resize', 'DisplaySize'], // Excludes 'Align'
            },
            //customTableResize: {}
        }

        if(readOnly) {
            return (
                <div className="ql-container ql-snow ql-disabled" style={{border: 'none'}}>
                    <div className="ql-editor" data-gramm="false" contentEditable="false">
                        <div dangerouslySetInnerHTML={{__html: value}} />
                    </div>
                </div>
            )
        }

        return (
            <div>
                <label className="QuillTextEditor-label">
                    {t(path)} {required && "*"}
                </label>
                <div style={{padding: '5px'}}>
                    <ReactQuill
                        ref={this.quillRef}
                        theme={'snow'}
                        readOnly={disabled}
                        onChange={this.handleChange}
                        value={value || ""}
                        modules={modules}
                        formats={Editor.formats}
                        placeholder={placeholder}
                    />
                </div>
                {touched === 'true' && (error && <ValidationUI error={error} />)}
                {/*{this.state.isTableSelectorOpen && (
                    <TableSelector
                        onSelect={this.handleTableSelect}
                        onClose={this.handleTableDialogClose}
                    />
                )}*/}
            </div>
        )
    }
}

export default Editor
