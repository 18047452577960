import _ from "lodash";
import moment from "moment";
import {generateFetchFieldListAction, GET_OBJECT_SUCCESS} from "../../../../../apps/KpModule/actions/api"
import {changeFieldProperty, setFieldEdition, setFieldVisibility} from "../../../../../apps/KpModule/actions";

export const module_ = {
    name: 'ReliefFundPayment',
    tKey: 'Saisie paiement',
    object: 'ReliefFund',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° du dossier'},
            {path: 'organizationName', width: 250},
            {path: 'object', width: 250},
            {path: 'totalAwardedAmounts', tooltip: true},
            {path: 'totalProposedAmounts', tooltip: true},
            {path: 'totalValidatedAmounts', tKey: 'dont Mt validé (€)', tooltip: true},
            {path: 'incompletePayments', tKey: 'En attente de justification', display: 'number', type: 'status', fieldPath: ['id', 'number', 'bStyle', 'style'], width: 250},
            //{path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], translate: true}
        ],
        form: {
            fields: [
                {path: 'applicationNumber', editable: false},
                {path: 'organizationName', editable: false},
                {path: 'object', editable: false},
                //{path: 'totalAwardedAmount', tKey: 'awardedAmount'},
                {
                    path: 'payments',
                    tKey: 'Suivi des paiements',
                    type: 'accordion',
                    removable: true,
                    viewMap: {
                        dt: [
                            { path: 'need', tKey: 'natureOfHelp', translate: true },
                            { path: 'awardedAmount' },
                            { path: 'amount', tKey: 'proposedAmount' },
                            { path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], translate: true },
                            { path: 'paymentDate', tKey: 'payé le'},
                            { path: 'noDeleteButtonAccess', hidden: true}
                        ],
                        form: [
                            {
                                path: 'need',
                                tKey: 'natureOfHelp',
                                filters: ['byReliefFund', 'hasAwardedAmount'],
                                fieldPath: ['id', 'name', 'awardedAmount'],
                                subscriptions: {
                                    onChange: (newValue, oldValue, { store, module }) => {
                                        if (newValue) {
                                            const paymentsField = module.viewMap.form.fields.find(
                                                field => field.path === 'payments'
                                            )

                                            const payments = paymentsField.getValue()

                                            const needPayments = payments
                                                .filter(payment => _.get(payment, 'status.id') !== 'refused' && payment.need?.id === newValue.id)
                                                .reduce((acc, payment) => {
                                                    if(!payment.amount) return acc
                                                    return acc + Number.parseInt(payment.amount)
                                                }, 0)

                                            const awardedAmountField = paymentsField.viewMap.form.fields.find(
                                                field => field.path === 'awardedAmount'
                                            )

                                            const balanceField = paymentsField.viewMap.form.fields.find(
                                                field => field.path === 'balance'
                                            )

                                            const amountField = paymentsField.viewMap.form.fields.find(
                                                field => field.path === 'amount'
                                            )

                                            const currentAmount = amountField.getValue() || 0

                                            awardedAmountField.setValue(newValue.awardedAmount)
                                            balanceField.setValue(newValue.awardedAmount - needPayments)
                                            store.dispatch(changeFieldProperty(amountField.id, 'maxValue', currentAmount + newValue.awardedAmount - needPayments))
                                        }
                                    }
                                }
                            },
                            { path: 'awardedAmount', disabled: true },
                            { path: 'balance', tKey: 'Solde (€)', disabled: true},
                            {
                                path: 'amount', tKey: 'proposedAmount', wholePositiveNumber: true, required: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, { module, store }) => {
                                        if(newValue) {
                                            const state = store.getState()
                                            const currentPaymentId =_.get(state, 'form.paymentsEditObject.values.id')
                                            const paymentsField = module.viewMap.form.fields.find(
                                                field => field.path === 'payments'
                                            )
                                            const needField = paymentsField.viewMap.form.fields.find(
                                                field => field.path === 'need'
                                            )
                                            const awardedAmountField = paymentsField.viewMap.form.fields.find(
                                                field => field.path === 'awardedAmount'
                                            )

                                            const payments = paymentsField.getValue()
                                            const need = needField.getValue()
                                            const awardedAmount = awardedAmountField.getValue()

                                            const needPayments = payments
                                                .filter(payment => _.get(payment, 'status.id') !== 'refused' && payment.need?.id === need.id && payment.id !== currentPaymentId)
                                                .reduce((acc, payment) => {
                                                    if(!payment.amount) return acc
                                                    return acc + Number.parseInt(payment.amount)
                                                }, Number.parseInt(newValue))

                                            const balanceField = paymentsField.viewMap.form.fields.find(
                                                field => field.path === 'balance'
                                            )
                                            balanceField.setValue(awardedAmount - needPayments)
                                        }
                                    }
                                }
                            },
                            { path: 'account', tKey: 'Compte bancaire', required: true },
                            { path: 'paymentDate', tKey: 'payé le' , endDate: moment().format('YYYY-MM-DD')},
                            { path: 'comments', tKeyText: 'Suivi' },
                            {
                                path: 'status', disabled: true, fieldPath: ['id', 'name', 'bStyle'], default: {id: 'draft', name: 'Brouillon', bStyle: 'default'},
                                subscriptions: {
                                    onChange: (newValue, oldValue, { store, module }) => {
                                        store.dispatch(setFieldVisibility('e_payments.e_paymentDate', newValue && newValue.id === 'validated'))
                                        store.dispatch(setFieldVisibility('e_payments.e_balance', newValue && ['draft', 'incomplete'].includes(newValue.id)))
                                        store.dispatch(setFieldEdition('e_payments.e_need', newValue && ['draft', 'incomplete'].includes(newValue.id)))
                                        store.dispatch(setFieldEdition('e_payments.e_amount', newValue && ['draft', 'incomplete'].includes(newValue.id)))
                                        store.dispatch(setFieldEdition('e_payments.e_account', newValue && ['draft', 'incomplete'].includes(newValue.id)))
                                        const paymentsField = module.viewMap.form.fields.find(field => field.path === 'payments')
                                        const DeleteButtonAccessField = paymentsField.viewMap.form.fields.find(field => field.path === 'noDeleteButtonAccess')
                                        DeleteButtonAccessField.setValue(newValue && !['draft', 'incomplete'].includes(newValue.id))
                                    }
                                }
                            },
                            { path: 'noDeleteButtonAccess', hidden: true}
                        ]
                    }
                },
                {path: 'submitter', fieldPath: ['id', 'firstname', 'lastname', 'mail'], hidden: true},
                {path: 'comments', hidden: true},
                {path: 'buttons', hidden: true}
            ]
        }
    },
    filters: [
        'userHasAuthorization',
        {
            name: 'byStatusForDecision',
            query: () => {
                return { status: 'accepted'}
            }
        }
    ],
    actionSubscriptions: [
        {
            actionType: GET_OBJECT_SUCCESS,
            subscription: ({ store }) => {
                const state = store.getState()
                const reliefFund = _.pick(state.edit.object.data, ['id'])

                store.dispatch(generateFetchFieldListAction(
                    'm-C-reliefFundPayment.Payment_need',
                    store.getState,
                    'form',
                    {
                        data: {reliefFund}
                    }

                ))
            }
        }
    ]
}
