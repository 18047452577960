import {setFieldVisibility} from "../../../../../apps/KpModule/actions";
import _ from "lodash";

export const module_ = {
    name: 'Study',
    tKey: 'Étude dossier',
    object: 'ReliefFund',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: false,
    removable: false,
    viewMap: {
        dt: [
            {path: 'applicationNumber'},
            {path: 'organizationName'},
            {path: 'needsTypes'},
            {path: 'object'},
            {path: 'totalTotalAmounts', tooltip: true},
            {path: 'totalRequestedAmounts', tooltip: true},
            {path: 'fundingRate'},
            {path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], translate: true}
        ],
        form: {
            fields: [
                {path: 'applicationNumber', editable: false},
                {path: 'organizationName', editable: false},
                {path: 'object', editable: false},
                {path: 'description', tKey: 'Présentation des difficultés rencontrées', type: 'richTextEditor', editable: false},
                {path: 'associationUpdatedStatutes', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', editable: false},
                {path: 'associationStatusNotice', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', editable: false},
                {path: 'latestAssociationReport', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', editable: false},
                {path: 'insuranceContract', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', editable: false},
                {path: 'bankIdentityStatement', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', editable: false},
                {path: 'associationInformationUpToDate', editable: false},
                {path: 'shelterInformationUpToDate', editable: false},
                {path: 'shelterCapacityInformationUpToDate', editable: false},
                {path: 'landType', display: 'name2', editable: false, translate: true},
                {path: 'landTypeProof', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', editable: false},
                {
                    path: 'needs',
                    type: 'accordion',
                    newable: false,
                    viewMap: {
                        dt: [
                            { path: 'natureOfHelp'},
                            { path: 'totalAmount' },
                            { path: 'requestedAmount' }
                        ],
                        form: [
                            { path: 'natureOfHelp', editable: false},
                            { path: 'totalAmount', editable: false },
                            { path: 'requestedAmount', editable: false },
                            { path: 'supportingDocuments', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', tKey: "needSupportingDocuments_submission", editable: false}
                        ]
                    }
                },
                {path: 'statementOfHonestyAndAccuracy', editable: false},
                {
                    path: 'ongoingLiberalities',
                    subscriptions: {
                        onChange: (newValue, oldValue, {store, module, formInitialize, getObjectSuccessAction}) => {
                            store.dispatch(setFieldVisibility('e_currentSuccessionsName', !!newValue))
                            store.dispatch(setFieldVisibility('e_estimatedAmount', !!newValue))
                            if(!newValue && (!formInitialize && !getObjectSuccessAction)) {
                                const currentSuccessionsNameField = module.viewMap.form.fields.find(field => field.path === 'currentSuccessionsName')
                                const estimatedAmountField = module.viewMap.form.fields.find(field => field.path === 'estimatedAmount')
                                currentSuccessionsNameField.setValue('')
                                estimatedAmountField.setValue(null)
                            }
                        }
                    }
                },

                {path: 'currentSuccessionsName', required: true},
                {path: 'estimatedAmount', wholeNumber: true, required: true},
                /*
                {path: 'files', tKey: 'Fichiers', editable: false},
                 */
                {path: 'studyCommitteeComment', type: 'richTextEditor'},
                {path: 'comments', tKeyText: 'Suivi'},
                {path: 'submitter', fieldPath: ['id', 'firstname', 'lastname', 'mail'], hidden: true},
                {path: 'submitterHasShelters', hidden: true},
                {path: 'buttons', hidden: true}
            ],
            onOpen: ({state, store}) => {
                const submitterHasShelters = _.get(state, 'edit.object.data.submitterHasShelters')

                store.dispatch(setFieldVisibility( 'e_shelterInformationUpToDate', submitterHasShelters))
                store.dispatch(setFieldVisibility( 'e_shelterCapacityInformationUpToDate', submitterHasShelters))
            }
        }
    },
    filters: [
        'userIsController',
        {
            name: 'byStatusForStudy',
            query: () => {
                return { status: {$in: ['ongoing', 'updated']}}
            }
        }
    ]
}
