import React, { PureComponent } from 'react'
import Draggable from "react-draggable";
import classnames from "classnames";
import Table, {
    Column,
    Board,
    FileCell,
    TrashCell,
    AutoSizer,
    BooleanCell,
    StatusTag
} from '../../../../components/DataTable'
import CheckBox from '../../../../components/DataTable/CheckBox'
import {htmlDecode} from '../../../../utils/utils'
import { connect } from 'react-redux'
import { toastr } from 'react-redux-toastr'
import _ from 'lodash'
import { fetchFormObject, deleteObject, deleteObjects, applyAction, fetchDtData, exportTableObject } from '../../actions/api'
import {executeLoadSubscriptions, executeDtLoadSubscriptions} from '../../actions/module'
import {
    sortDataTable,
    setFiltersOpened,
    filterDataTable,
    setColumnSelectorOpened,
    setActionSelectorOpened,
    toggleColumnVisibility,
    setObjectMode,
    setListElementSelection,
    setFieldWidth
} from '../../actions'
import {
    getFiltersExist,
    getDataTableColumns,
    getSortBy,
    getSortDirection,
    getDataTableFilter,
    getFiltersOpened,
    getEditFieldsLength,
    getColumnSelectorOptions,
    getColumnSelectorOpened,
    getActionSelectorOpened,
    getDataTableObjects,
    getLanguage,
    getUser
} from '../../selectors'
import './KpDataTable.css'
import { defaultHeaderRenderer } from 'react-virtualized/dist/commonjs/Table'
import {defaultTableHeaderRowRenderer} from "react-virtualized";
import HoverPopover from "../../../../components/HoverPopover";
import {Film, FileText} from "react-feather";

const getFormattedAmount = (amount, language) => {
    const parsed = parseFloat(amount)
    if(parsed) return new Intl.NumberFormat(language).format(parsed)
}

function cellRenderer(field, user, language, groupModel) {
    switch (field.type) {
        case 'boolean':
            return ({ cellData }) => <BooleanCell value={cellData} />
        case 'status':
            return ({ cellData }) => <StatusTag value={cellData.value} bStyle={cellData.bStyle} style={cellData.style} />
        case 'videoIcon':
            return ({ cellData }) => {
                if(!cellData) return

                return <Film size={16}/>
            }
        case 'creatableTags':
            return ({cellData}) => {
                console.log('cellData', cellData)
                return (
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        {cellData.map(value => (
                            <div style={{
                                padding: '3px',
                                margin: '3px',
                                borderRadius: '4px',
                                background: '#addbff91',
                                border: '1px solid #96bedc'
                            }}>
                                {value}
                            </div>
                        ))}
                    </div>
                )
            }
        case 'documentIcon':
            return ({ cellData }) => {
                if(!cellData) return

                return <FileText size={16}/>
            }
        case 'number':
            return ({ cellData }) => <div style={{textAlign: 'end'}}>{getFormattedAmount(cellData, language)}</div>
        case 'fileLink':
            return ({ rowData }) => {
                const data = _.get(rowData, field.path)
                return data
                    ? <FileCell fileId={data.id} filename={data.filename} password={data.password} language={language} groupModel={groupModel} />
                    : undefined
            }
        default:
            return undefined
    }
}

class KpDataTable extends PureComponent {
    componentDidMount() {
        this.props.executeDtLoadSubscriptions()
    }

    enhanceColumns(columns) {
        const { t, user, language, groupModel } = this.props
        const filteredColumns = columns.filter(column => !column.hidden)
        return filteredColumns.map((column, index) => {
            const nextColumn = filteredColumns[index + 1]
            return {
                ...column,
                dataKey: column.path,
                label: t(column.label || column.tKey),
                className: classnames('RowColumn', column.className ? column.className : '') ,
                headerClassName: column.headerClassName,
                cellDataGetter: ({ rowData }) => column.dataGetter(rowData),
                cellRenderer: cellRenderer(column, user, language, groupModel),
                headerRenderer: this.renderColumnHeader(column, nextColumn)
            }
        })
    }

    getDeleteColumn(deletionConditions) {
        const remove = rowData => () => this.props.onDeleteClick(rowData)

        return {
            dataKey: 'id',
            label: '',
            width: 20,
            flexGrow: 0,
            flexShrink: 0,
            className: 'RowColumn-trashCell',
            disableSort: true,
            disableFilter: true,
            disableExport: true,
            disableHide: true,
            cellRenderer: ({ rowData }) => {

                if(!rowData.noDeleteButtonAccess) {
                    return deletionConditions
                        ? Object.keys(deletionConditions).some(key => deletionConditions[key].includes(rowData[key]))
                            ? <TrashCell onClick={ remove(rowData) } />
                            : null
                        : <TrashCell onClick={ remove(rowData) } />
                }
            }
        }
    }

    getDeleteCheckboxColumn() {
        const selection = rowData => isChecked => this.props.onSelectCheckBoxClick(rowData.id, isChecked)

        return {
            dataKey: 'selected',
            label: '',
            width: 30,
            flexGrow: 0,
            flexShrink: 0,
            className: 'RowColumn-checkbox',
            disableSort: true,
            disableFilter: true,
            disableExport: true,
            disableHide: true,
            cellRenderer: ({ rowData }) => {
                return <CheckBox handleCheckboxChange={selection(rowData)} selected={!!rowData.selected} disabled={rowData.disableGroupedAction}/>
            }
        }
    }

    headerRowRenderer(props, selectedColumns, t) {
        const {style} = props
        const groupedByParent = _.groupBy(selectedColumns, 'parentColumn')
        const parentColumns = Object.keys(groupedByParent).map(key => {
            return groupedByParent[key].reduce((acc, column) => {
                acc.width += column.width
                acc.nb++
                return acc
            }, { path: key, width: 0, nb: 0 })
        })
        return (
            <div>
                <div className='DataTable-Table-parent-headerRow' style={{...style, borderBottom: '1px solid #adb0ba'}}>
                    {parentColumns.map((column, index) => <div style={{
                        flex: `${column.nb} ${column.width}px`,
                        fontWeight: 'bold',
                        fontSize: '14px',
                        marginTop: '10px',
                        marginRight: `${10 * column.nb}px`,
                        textTransform: 'none',
                        outline: 'none',
                        marginLeft: index ? '0' : '10px',
                    }}>
                        {t(column.path)}
                    </div>)}
                </div>
                {defaultTableHeaderRowRenderer(props)}
            </div>
        )

    }

    renderColumnHeader = (column, nextColumn) => {
        const {objects, setFieldWidth, t} = this.props

        return headerParams => {
            const getNumber = o => {
                const numberCandidate = Number(_.get(o, column.path))
                return _.isNaN(numberCandidate) ? 0 : numberCandidate
            }
            const total = _.round(
                objects.reduce((acc, o) => getNumber(o) + acc, 0)
            )

            const headerRenderer = defaultHeaderRenderer(headerParams)

            let children = []

            if (column.tooltip) {
                children.push(
                    <HoverPopover
                        key="overlay"
                        id={'KpDataTable-tooltip'}
                        message={`${t('total')} : ${total}`}
                        placement={'top'}
                    >
                        {headerRenderer[0]}
                    </HoverPopover>
                )
            } else {
                children.push(headerRenderer[0])
            }


            // the second element is the sort indicator
            if (headerRenderer[1]) {
                children.push(headerRenderer[1])
            }

            if(nextColumn && !nextColumn.notDraggable) {
                children.push(
                    <div
                        onClick={event => event.stopPropagation()}
                    >
                        <Draggable
                            axis="x"
                            defaultClassName="DragHandle"
                            defaultClassNameDragging="DragHandleActive"
                            onDrag={(event, data) => {
                                const delta = data.deltaX;
                                const columnWidth = column.width + delta
                                const nextColumnWidth = nextColumn.width - delta

                                setFieldWidth(column.id, columnWidth)
                                setFieldWidth(nextColumn.id, nextColumnWidth)
                            }}
                            position={{ x: 0 }}
                            zIndex={999}
                        >
                            <span className="DragHandleIcon">⋮</span>
                        </Draggable>
                    </div>
                )
            }
            return children
        }
    }

    render() {
        const {
            columns,
            module,
            objects,
            onLineClick,
            sortBy,
            sortDataTable,
            sortDirection,
            t,
            user,
            editFields,
            filtersExist,
            filtersOpened,
            setFiltersOpened,
            dataTableFilter,
            filterDataTable,
            actionSelectorOpened,
            setActionSelectorOpened,
            columnSelectorOptions,
            columnSelectorOpened,
            setColumnSelectorOpened,
            toggleColumnVisibility,
            setObjectMode,
            deleteListElements,
            onActionClick,
            exportTableObject,
            language
        } = this.props

        const changeFiltersVisibility = filtersExist
            ? () => setFiltersOpened(!filtersOpened)
            : undefined

        const tableColumns = _.compact([
            (module.removableGroup || module.workflowActions.length !== 0) ? this.getDeleteCheckboxColumn() : undefined,
            ...this.enhanceColumns(columns),
            module.removable ? this.getDeleteColumn(module.deletionConditions) : undefined
        ])

        const onRowClick = editFields
            ? ({ rowData }) => {
                if(!rowData.noFormAccess) {
                    setObjectMode('editObject')
                    onLineClick(rowData)
                }
              }
            : undefined

        return (
            <div className="KpDataTable">
                <Board
                    actions={module.workflowActions}
                    noLeftBoard={module.noLeftBoard}
                    useSocketsOnGet={module.useSocketsOnGet}
                    useSocketsOnFind={module.useSocketsOnFind}
                    actionSelectorOpened={actionSelectorOpened}
                    openActionSelector={setActionSelectorOpened}
                    selectorOptions={columnSelectorOptions.map(option => ({
                        ...option,
                        label: t(option.tKey)
                    }))}
                    columnSelectorOpened={columnSelectorOpened}
                    openColumnSelector={setColumnSelectorOpened}
                    changeColumnSelection={toggleColumnVisibility}
                    objects={objects}
                    columns={this.enhanceColumns(columns)}
                    filter={dataTableFilter}
                    changeFilter={filterDataTable}
                    changeFiltersVisibility={changeFiltersVisibility}
                    searchTooltip={t(
                        objects.length > 1
                            ? 'elementTooltip_plural'
                            : 'elementTooltip',
                        {
                            count: objects.length
                        }
                    )}
                    searchPlaceholder={`${t('search')}...`}
                    moduleName={t(module.tKey)}
                    deleteListElements={module.removableGroup && deleteListElements}
                    onActionClick={onActionClick}
                    exportTableObject={exportTableObject}
                    moduleId={module.id}
                    protectedExport={module.protectedExport}
                    user={user}
                    t={t}
                    language={language}
                />
                <div className="KpDataTable-Table">
                    <AutoSizer>
                        {({ width, height }) => (
                            <Table
                                alerts={module.alerts}
                                objects={objects}
                                width={width}
                                height={height}
                                onRowClick={onRowClick}
                                sortBy={sortBy}
                                sortDirection={sortDirection}
                                sort={module.sortable && sortDataTable}
                                noRowsLabel={t('noRows')}
                                autoGrow={true}
                                headerClassName={module.headerClassName}
                                headerRowClassName={module.headerRowClassName}
                                headerHeight={module.headerHeight}
                                headerRowRenderer={module.parentHeader ? (props => this.headerRowRenderer(props, tableColumns, t)) : defaultTableHeaderRowRenderer}
                            >
                                {tableColumns.map((column, i) => (
                                    <Column key={i} {...column} />
                                ))}
                            </Table>
                        )}
                    </AutoSizer>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    columns: getDataTableColumns(state),
    dataTableFilter: getDataTableFilter(state),
    filtersExist: getFiltersExist(state),
    filtersOpened: getFiltersOpened(state),
    objects: getDataTableObjects(state),
    user: getUser(state),
    sortBy: getSortBy(state),
    sortDirection: getSortDirection(state),
    language: getLanguage(state),
    editFields: getEditFieldsLength(state),
    columnSelectorOptions: getColumnSelectorOptions(state),
    columnSelectorOpened: getColumnSelectorOpened(state),
    actionSelectorOpened: getActionSelectorOpened(state),
})

const mapDispatchToProps = (dispatch, {module, groupModel, t }) => {
    return {
        setFieldWidth: (fieldId, width) => dispatch(setFieldWidth(fieldId, width)),
        setObjectMode: object => dispatch(setObjectMode(object)),
        filterDataTable: value => dispatch(filterDataTable(value)),
        setFiltersOpened: opened => dispatch(setFiltersOpened(opened)),
        exportTableObject: (filename, columns, objects, user, protectedExport, language) =>
            dispatch(exportTableObject(filename, columns, objects, user, protectedExport, language, groupModel)),
        setColumnSelectorOpened: ({ opened }) => {
            dispatch(setColumnSelectorOpened(opened, groupModel))
        },
        setActionSelectorOpened: ({ opened }) =>
            dispatch(setActionSelectorOpened(opened)),
        toggleColumnVisibility: ({ key }) =>
            dispatch(toggleColumnVisibility(key)),
        sortDataTable: ({ sortBy, sortDirection }) =>
            dispatch(sortDataTable(sortBy, sortDirection)),
        executeDtLoadSubscriptions: () => dispatch(executeDtLoadSubscriptions()),
        onLineClick: object => {
            dispatch(fetchFormObject(object.id))
                .then(({ error }) => {
                    if(!error) dispatch(executeLoadSubscriptions())
                })
        },
        onDeleteClick: object => {
            toastr.confirm(t(module.deletionConfirmationMessage || 'deletionConfirmation'), {
                onOk: () =>
                    dispatch(deleteObject(object.id)).then(({ error }) => {
                        if(error) {
                            const decodedError = htmlDecode(error)
                            toastr.error(t('deletion'), t(decodedError))
                        }
                        else toastr.success(t('deletion'), t('deletionSuccess', {number: 1}))
                    })
            })
        },
        onSelectCheckBoxClick: (id, isChecked) => dispatch(setListElementSelection(id, isChecked)),
        deleteListElements: () => {
            toastr.confirm(t('deletionConfirmation'), {
                onOk: () => {
                    dispatch(deleteObjects()).then((results) => {
                            const failed = results.filter(({error}) => error)
                            const succeed = results.filter(({error}) => !error)

                            if(succeed.length) toastr.success(t('deletion'), t('deletionSuccess', {number: succeed.length}))

                            if(failed.length) {
                                toastr.error(t('deletion'), t('deletionFailed', {number: failed.length}))
                            }
                        }
                    )
                }
            })
        },
        onActionClick: (action, moduleId) => {
            toastr.confirm(t('actionConfirmation', {action: t(action)}), {
                onOk: () => {
                    dispatch(applyAction(action)).then((results) => {
                            const failed = results.filter(({error}) => error)
                            const succeed = results.filter(({error}) => !error)

                            if(succeed.length) {
                                dispatch(fetchDtData(moduleId))
                                toastr.success(t('action'), t('actionSuccess', {number: succeed.length}))
                            }

                            if(failed.length) {
                                toastr.error(t('action'), t('actionFailed', {number: failed.length, errors: ["coco", "kiki"]}))
                            }
                        }
                    )
                }
            })
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KpDataTable)
