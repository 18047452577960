import React, {useState} from "react";
import {AlignCenter, AlignLeft, AlignRight, ChevronRight, Image, Trash, Type} from "react-feather";

const ImageContextMenu = ({ x, y, onClose, onAction, subMenuPosition }) => {
    const [activeSubmenu, setActiveSubmenu] = useState(null);

    const handleMouseEnter = (submenu) => {
        setActiveSubmenu(submenu);
    };

    const handleMouseLeave = () => {
        setActiveSubmenu(null);
    };

    return (
        <div
            className="image-context-menu"
            style={{
                position: 'fixed',
                top: `${y}px`,
                left: `${x}px`,
                background: 'white',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
                zIndex: 1000,
            }}
            onMouseLeave={onClose}
        >
            <ul style={{listStyle: 'none', margin: 0, padding: '4px 0'}}>
                {/* Alignment Submenu */}
                <li
                    className="context-menu-item"
                    onMouseEnter={() => handleMouseEnter('alignment')}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <Image size={20}/>
                    Position exclusive
                    <ChevronRight size={18} className="rightIcons"/>
                    {activeSubmenu === 'alignment' && (
                        <div
                            className="submenu"
                            style={{
                                ...subMenuPosition
                            }}
                        >
                            <ul style={{listStyle: 'none', margin: 0, padding: '4px 0'}}>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignLeft')}
                                >
                                    <AlignLeft size={18}/>
                                    A gauche
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignCenter')}
                                >
                                    <AlignCenter size={18}/>
                                    Au centre
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignRight')}
                                >
                                    <AlignRight size={18}/>
                                    A droite
                                </li>
                            </ul>
                        </div>
                    )}
                </li>

                <li
                    className="context-menu-item"
                    onMouseEnter={() => handleMouseEnter('alignmentWithText')}
                    onMouseLeave={() => handleMouseLeave()}
                >
                    <AlignLeft size={20}/>
                    Position avec texte
                    <ChevronRight size={18} className="rightIcons"/>
                    {activeSubmenu === 'alignmentWithText' && (
                        <div
                            className="submenu"
                            style={{
                                ...subMenuPosition
                            }}
                        >
                            <ul style={{listStyle: 'none', margin: 0, padding: '4px 0'}}>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignLeftWithText')}
                                >
                                    <AlignLeft size={18}/>
                                    Image + Paragraphe à droite
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignRightWithText')}
                                >
                                    <AlignRight size={18}/>
                                    Paragraphe à gauche + Image
                                </li>
                                <li
                                    className="context-menu-item"
                                    onClick={() => onAction('alignCenterWithText')}
                                >
                                    <Type size={18}/>
                                    Intégrer l'image dans le texte
                                </li>
                            </ul>
                        </div>
                    )}
                </li>

                {/* Remove Image Option */}
                <li
                    className="context-menu-item"
                    onClick={() => onAction('removeImage')}
                >
                    <Trash size={20}/>
                    Supprimer l'image
                </li>
            </ul>
        </div>
    );
};

export default ImageContextMenu