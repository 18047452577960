//import {setFieldVisibility} from "../../../../../apps/KpModule/actions"
//import {organizationActivities} from "../../staticEntities";

import {setFieldEdition} from "../../../../../apps/KpModule/actions";
import _ from "lodash";

export const module_ = {
    object: 'LiberalityFile',
    name: 'LiberalityFile',
    tKey: 'Dossier',
    objectIdentifier: 'applicationNumber',
    category: {
        path: 'Libéralités',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° dossier'},
            {path: 'presentationDate', tKey: 'Présentation'},
            {path: 'administrator', display: 'fullName', tKey: 'Gestionnaire'},
            {path: 'beneficiary', tKey: 'Bénéficiaire'},
            {path: 'zipCode', tKey: 'Code postal'},

            {path: 'totalAmount', tKey: 'Total net (€)', tooltip: true},
            {path: 'netAssets', tKey: 'Dont Legs (€)', tooltip: true},
            {path: 'lifeInsuranceNetAmount', tKey: 'Dont A.Vie (€)', tooltip: true},

            {path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], width: 280},
            {path: 'updatedAt', tKey: 'Date'},
            {path: 'updatedBy', tKey: 'Utilisateur'},
            {path: 'noDeleteButtonAccess', hidden: true},
        ],
        form: {
            fields: [
                {path: 'applicationNumber', tKey: 'N° dossier', disabled: true},
                {path: 'partiesSection', section: "partiesSection", data: 'Parties', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {path: 'administrator', display: 'fullName', tKey: 'Gestionnaire', filters: ['eligibleToLiberalityProcess'], section: "partiesSection"},
                {
                    path: 'beneficiary',
                    tKey: 'Bénéficiaire',
                    filters: ['isAssociation'],
                    section: "partiesSection",
                    fieldPath: ['id', 'name', 'zipCode'],
                    subscriptions: {
                        onChange: (newValue, oldValue, { module }) => {
                            const zipCodeField = module.viewMap.form.fields.find(
                                field => field.path === 'zipCode'
                            )
                            if(newValue) {
                                console.log('newValue', newValue)
                                zipCodeField.setValue(newValue.zipCode)
                            }
                            else
                                zipCodeField.setValue("")
                        }
                    }
                },
                {path: 'zipCode', tKey: "code postal", section: "partiesSection", disabled: true},
                {path: 'testator', tKey: "Testateur", section: "partiesSection", required: true},
                {path: "testatorComment", tKey: 'Commentaire testateur', type: "richTextEditor", section: "partiesSection"},

                {path: 'legacySection', section: "legacySection", data: 'Legs', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {path: 'object', tKey: "Objet", type: "richTextEditor", section: "legacySection", required: true},
                {path: 'grossAssets', tKey: 'Actif brut (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true},
                {path: 'grossAssetsDetails', tKey: 'Détails liés à l’actif brut (€)', type: "richTextEditor", section: "legacySection"},

                {path: 'netAssets', tKey: 'Actif net (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true},
                {path: 'netAssetsDetails', tKey: 'Détails liés à l’actif net (€)', type: "richTextEditor", section: "legacySection"},

                {path: 'grossLiabilities', tKey: 'Passif brut (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true},
                {path: 'grossLiabilitiesDetails', tKey: 'Détails liés au passif brut (€)', type: "richTextEditor", section: "legacySection"},

                {path: 'actsFees', tKey: 'Frais d’actes (€)', wholePositiveNumber: true, section: "legacySection", default: 0, required: true},
                {path: 'actsFeesDetails', tKey: 'Détails liés aux frais d’actes', type: "richTextEditor", section: "legacySection"},

                {path: 'lifeInsuranceSection', section: "lifeInsuranceSection", data: 'Assurance-vie', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {path: 'lifeInsuranceNetAmount', tKey: 'Montant net (€)', wholePositiveNumber: true, section: "lifeInsuranceSection", default: 0, required: true},
                {path: 'lifeInsuranceNetAmountDetails', tKey: 'Détails liés à l’assurance-vie', type: "richTextEditor", section: "lifeInsuranceSection"},

                {path: 'documentsAndMonitoring', section: "documentsAndMonitoring", data: 'Documents & Suivi', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {path: 'files', tKeyText: "Fichiers", section: "documentsAndMonitoring"},
                {path: 'comments', tKeyText: "Suivi", section: "documentsAndMonitoring"},

                {path: 'applicationNumber', hidden: true},
                {path: 'status', hidden: true},
                {path: 'buttons', hidden: true}

            ],
            onOpen: ({ store, module }) => {
                const state = store.getState()
                const statusId = _.get(state, 'edit.object.data.status.id')
                const partiesSectionFields = module.viewMap.form.fields.filter(field => field.section === "partiesSection")
                const legacySectionFields = module.viewMap.form.fields.filter(field => field.section === "legacySection")
                const lifeInsuranceSectionFields = module.viewMap.form.fields.filter(field => field.section === "lifeInsuranceSection")
                const documentsAndMonitoringFields = module.viewMap.form.fields.filter(field => field.section === "documentsAndMonitoring")

                partiesSectionFields.forEach(field => store.dispatch(setFieldEdition(field.id, ['draft', 'incomplete'].includes(statusId))))
                legacySectionFields.forEach(field => store.dispatch(setFieldEdition(field.id, ['draft', 'incomplete'].includes(statusId))))
                lifeInsuranceSectionFields.forEach(field => store.dispatch(setFieldEdition(field.id, ['draft', 'incomplete'].includes(statusId))))
                documentsAndMonitoringFields.forEach(field => store.dispatch(setFieldEdition(field.id, ['draft', 'incomplete'].includes(statusId))))
            }
        }
    },
    filters: ['byStatus']
}
