import {getButtonsForModule, getUsersWithAuthorization, prepareDefaultMail} from "./utils"
import _ from "lodash";
import moment from "moment/moment";
import {basicContext} from "../../../utils/contextUtils";
import async from "async";
import Errors from "../../../utils/Errors";
import {decrypt} from "../../../utils/crypto";

export const entities = [
    {
        name: 'Payment',
        facets: [
            'comments'
        ],
        fields: [
            {type: 'Need'},
            {
                path: 'natureOfHelp',
                fieldPath: ['need.natureOfHelp.name'],
                $f: function(payment, context, callback) {
                    return callback(null, _.get(payment, 'need.natureOfHelp.name'))
                }
            },
            {
                path: 'reliedFundApplicationNumber',
                fieldPath: ['need.reliefFund.applicationNumber'],
                f: function() {
                    return this.need?.reliefFund.applicationNumber
                }
            },
            {
                path: 'awardedAmount',
                fieldPath: ['need.awardedAmount'],
                f: function() {
                    return this.need?.awardedAmount
                }
            },
            {
                path: 'organizationName',
                fieldPath: ['need.reliefFund.organizationName'],
                f: function() {
                    return this.need?.reliefFund?.organizationName
                }
            },
            {path: 'amount', type: 'integer'},
            {path: 'account', type: 'Bank'},
            {path: 'status', type: 'ReliefFundPaymentStatus', nullable: true},
            {path: 'paymentDate', type: 'date', nullable: true},
            {
                path: 'buttons',
                fieldPath: ['status.id'],
                $f: function (payment, context, callback) {
                    const buttons = getButtonsForModule(payment, context);
                    callback(null, buttons);
                }
            },
            {
                path: 'object',
                fieldPath: ['need.reliefFund.object'],
                f: function() {
                    return this.need?.reliefFund?.object
                }
            },
            {
                path: 'balance',
                fieldPath: [
                    'need.reliefFund.totalAwardedAmounts',
                    'need.reliefFund.totalProposedAmounts'
                ],
                f: function() {
                    const totalAwardedAmounts = _.get(this, 'need.reliefFund.totalAwardedAmounts')
                    const totalProposedAmounts = _.get(this, 'need.reliefFund.totalProposedAmounts')
                    return parseInt(totalAwardedAmounts) - parseInt(totalProposedAmounts)
                }
            },
            {
                path: "noDeleteButtonAccess",
                fieldPath: ['status.id'],
                $f: function (payment, context, callback) {
                    const noDeleteAccess = !['draft', 'incomplete'].includes(_.get(payment, 'status.id'))

                    // Call the callback function with the result
                    // true means no delete access, false means delete access is allowed
                    callback(null, noDeleteAccess)
                }
            }
        ],
        filters: [
            {
                name: 'byStatus',
                path: 'statuses',
                object: 'ReliefFundPaymentStatus',
                display: 'name',
                type: 'tags',
                filters: [
                    {
                        path: 'notDraft',
                        match: object => object.id !== 'draft'
                    }
                ],
                default: [{id: 'ongoing'}],
                client: true,
                width: 5,
                clearable: false,
                query: (context) => {
                    const statuses = _.get(context, 'data.statuses', [])
                    return { status: {$in: statuses.map(status => status.id)} }
                }
            },
            {
                name: 'byAcceptedFiles',
                path: 'reliefFund',
                tKey: 'reliefFundFile',
                object: 'ReliefFund',
                filters: ['isAccepted', 'hasOngoingPayment'],
                display: 'fullName',
                client: true,
                width: 7,
                clearable: true,
                default: {id: null},
                query: (context) => {
                    const reliefFundId = _.get(context, 'data.reliefFund.id')
                    return reliefFundId
                        ? { reliefFund: new global.ObjectID(reliefFundId) }
                        : {}
                }
            }
        ],
        authorizationsValidation: function (cUser, context, callback) {
            const moduleId = context.clientContext.moduleId
            const authorizationProperty = 'decisionAuthorization'

            const hasAuthorization = cUser.functions.some(userFunction => !!userFunction[authorizationProperty])

            if (moduleId === 'm-C-reliefFundPaymentValidation' && !hasAuthorization) {
                return callback(new Errors.ValidationError(`${authorizationProperty}Denied`))
            }

            return callback()
        },
        validateSave: async function (newObject, oldObject, context, callback) {
            const cUser = await global.app.C.CUser.get({kpUser: global.ObjectID(context.user.id)}, {
                ...basicContext(context),
                fieldPath: [
                    'id',
                    'functions.id',
                    'functions.submissionAuthorization',
                    'functions.studyAuthorization',
                    'functions.decisionAuthorization',
                    'functions.paymentAuthorization',
                    'organization.id',
                    'shelters.id'
                ]
            })
            async.series([
                callback => this.authorizationsValidation(cUser, context, callback)
            ], callback)
        },
        beforeSave: function(newObject, oldObject, context, callback) {
            const buttonAction = context.action
            let action = ''
            switch (buttonAction) {
                case 'validate':
                    newObject.status.id = 'validated'
                    action = 'Paiement validé'
                    break
                case 'refuse':
                    newObject.status.id = 'refused'
                    action = 'Paiement refusé'
                    break
                case 'justify':
                    newObject.status.id = 'incomplete'
                    action = 'En attente de justification'
                    break
            }
            newObject.comments.push({
                user: _.pick(context.user, ['id', 'name']),
                text: `${action} : ${newObject.amount} € - ${newObject.account.name}`,
                date: moment().format("YYYY-MM-DD HH:mm")
            })

            return callback(null, newObject, oldObject)
        },
        /*
        afterSave: async function(newObject, oldObject, context, callback) {
            const recipients = await getUsersWithAuthorization('paymentAuthorization')
            const defaultMail = prepareDefaultMail(context)

            const statusId = _.get(newObject, 'status.id')
            const content = {}

            switch (statusId) {
                case 'validated':
                    content.template = 'payer_validation_notification.html'
                    content.subject = `Paiement validé`
                    break
                case 'refused':
                    content.template = 'payer_rejection_notification.html'
                    content.subject = `Paiement refusé`
                    break
                case 'incomplete':
                    content.template = 'payer_justification_notification.html'
                    content.subject = `Demande de justification`
            }

            const mails = recipients.map(recipient => {
                return _.defaults(
                    {
                        to: recipient.mail && decrypt(recipient.mail),
                        content: content.template,
                        subject: { template: content.subject },
                        context: {
                            firstname: recipient.firstname,
                            applicationNumber: newObject.reliedFundApplicationNumber
                        }
                    },
                    defaultMail
                )
            })

            return global.mailer.sendMail(mails, (error) => {
                console.log(error)
                return callback()
            })
        }
         */
    }
]
