import {changeFieldProperty, setFieldVisibility, setFormButtons} from "../../../../../apps/KpModule/actions";
import {returnButton, saveButton, submitButton} from "../../utils";
import {getDataListList} from "../../../../../apps/KpModule/selectors";

export const module_ = {
    name: 'Submission',
    tKey: 'Dépôt dossier',
    object: 'ReliefFund',
    category: {path: 'Fonds de secours', icon: 'icon_path'},
    newable: true,
    removable: true,
    defaultSortBy: 'applicationNumber',
    defaultSortDirection: 'ASC',
    viewMap: {
        dt: [
            {path: 'applicationNumber'},
            {path: 'object'},
            {path: 'totalTotalAmounts'},
            {path: 'totalRequestedAmounts'},
            {path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], translate: true},
            {path: 'noDeleteButtonAccess', hidden: true}
        ],
        form: {
            fields: [
                {path: 'object', required: true},
                {path: 'description', tKey: 'Présentation des difficultés rencontrées', type: 'richTextEditor', required: true},
                {path: 'associationUpdatedStatutes', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', required: true},
                {path: 'associationStatusNotice', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', required: true},
                {path: 'latestAssociationReport', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', required: true},
                {path: 'insuranceContract', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', required: true},
                {path: 'bankIdentityStatement', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', required: true},
                {path: 'associationInformationUpToDate', default: false},
                {path: 'shelterInformationUpToDate', default: false},
                {path: 'shelterCapacityInformationUpToDate', default: false},
                {
                    path: 'landType', tKey: "Le dossier concerne un lieu", display: 'name', required: true, translate: true, sortList: false,
                    subscriptions: {
                        onChange: (newValue, oldValue, {store, module, formInitialize, getObjectSuccessAction}) => {
                            const landTypeProofField = module.viewMap.form.fields.find(field => field.path === 'landTypeProof')
                            store.dispatch(setFieldVisibility(landTypeProofField.id, !!newValue && newValue.id !== 'nonApplicable'))
                            store.dispatch(changeFieldProperty(landTypeProofField.id, 'tKey', `landTypeProof_${newValue?.id}`))
                            if(!formInitialize && !getObjectSuccessAction) landTypeProofField.setValue([])
                        }
                    }
                },
                {path: 'landTypeProof', required: true, fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles'},
                {
                    path: 'needs',
                    type: 'accordion',
                    newable: true,
                    removable: true,
                    required: true,
                    viewMap: {
                        dt: [
                            { path: 'natureOfHelp'},
                            { path: 'totalAmount'},
                            { path: 'requestedAmount'},
                            //{ path: 'noDeleteButtonAccess' , hidden: true}
                        ],
                        form: [
                            { path: 'natureOfHelp', required: true, filters: ['eligibleToEmergencyFund']},
                            {
                                path: 'totalAmount', required: true, wholePositiveNumberStrict: true,
                                subscriptions: {
                                    onChange: (newValue, oldValue, {store, module}) => {
                                        if(newValue) {
                                            const needsField = module.viewMap.form.fields.find(field => field.path === 'needs')
                                            const requestedAmountField = needsField.viewMap.form.fields.find(field => field.path === 'requestedAmount')
                                            store.dispatch(changeFieldProperty(requestedAmountField.id, 'maxValue', newValue))
                                        }
                                    }
                                }
                            },
                            { path: 'requestedAmount', required: true, wholePositiveNumberStrict: true},
                            { path: 'supportingDocuments', fieldPath: ['id', 'filename', 'user.id', 'user.name'], type: 'simpleFiles', tKey: "needSupportingDocuments_submission", required: true}
                        ]
                    }
                },
                {path: 'comments', tKey: 'Commentaires', hidden: true},
                {path: 'statementOfHonestyAndAccuracy'},
                {path: 'submitter', fieldPath: ['id', 'firstname', 'lastname', 'mail'], hidden: true},
                {path: 'organization', hidden: true},
                {path: 'buttons', hidden: true}
            ],
            onOpen: ({state, store}) => {
                const objectMode = state.ui.objectMode
                if (objectMode === 'newObject') {
                    // Define the buttons for a new application form

                    store.dispatch(setFormButtons([
                        submitButton, saveButton, returnButton
                    ]))

                    store.dispatch(setFieldVisibility('e_landTypeProof', false))
                }
            }
        }
    },
    filters: [
        'userIsSubmitter',
        {
            name: 'byStatusForSubmission',
            query: () => {
                return {
                    status: { $in: ['draft', 'questioned'] }
                }
            }
        }
    ]
}
