import React from 'react'
import './StatusTag.css'
import classnames from "classnames";

export const StatusTag = ({ value, style, bStyle }) => {
    return (
        <span
            className={
                classnames(
                    'StatusTag-container',
                    {
                        empty: !value,
                        default: !!value && !bStyle,
                        [bStyle]: !!value && !!bStyle
                    }
                )
            }
            style={style}
        >
            {value}
        </span>
    )
}
