import async from "async";
import {getCurrentUsers} from "../models/edf-in/workflow/workflow"

const _ = require('lodash')

const moduleIdByStatus = {
    formX: 'm-I-bp1',
    buildOffer: 'm-I-bp2',
    submitOffer: 'm-I-bp4',
    realisation: 'm-I-bp5 bis'
}
async function getEdfinWorkflowNotificationData(groupId) {

    const ongoingBusinessProjects = await global.app.I.BusinessProject.collection.aggregate([
        {
            $match: {
                'workflow.type': 'inProgress',
                group: new global.ObjectID(groupId)
            }
        },
        {
            $project:{
                "country": 1,
                "typeOfOffer": 1,
                "estimatedTurnover": 1,
                "workflow.step": 1,
                "workflow.order": 1,
            }
        }

    ]).toArray();

    const userProjectCombinations = await Promise.all(
        ongoingBusinessProjects.map(businessProject => (async () => {
            const reconstructedProject = {
                id: businessProject._id.toString(),
                country: {id: businessProject.country.toString()},
                typeOfOffer: {id: businessProject.typeOfOffer.toString()},
                estimatedTurnover: businessProject.estimatedTurnover,
                workflow: {...businessProject.workflow}
            }
            const currentBPUsers = await getCurrentUsers(reconstructedProject, {group: {id: groupId}})
            return currentBPUsers.map(teamMember => ({user: teamMember.user, businessProject}))
        })())
    )


    const groupedByUser = _.groupBy(_.flatten(userProjectCombinations), 'user.id')

    return Object.keys(groupedByUser).reduce((acc, userId) => {
        const groupedByStatus = _.groupBy(groupedByUser[userId], 'businessProject.workflow.step')
        return {
            [userId]: Object.keys(groupedByStatus).reduce((acc, status) => ({
                [moduleIdByStatus[status]]: groupedByStatus[status].length,
                ...acc
            }), {}),
            ...acc

        }
    }, {})
}

function getSENotificationData(userId, groupId, callback) {
    return async.parallel([
        callback => global.app.SE.Habilitation.find(
            {
                query: {
                    user : {$eq : global.ObjectID(userId)},
                },
                fieldPath: ['role.id'],
                group: new global.ObjectID(groupId)
            },callback),
        callback => {
            global.app.SE.Habilitation.find({
                query: { user: global.ObjectID(userId) },
                fieldPath: ["role.id", "grantedAccess", "grantedMesh"],
                group: new global.ObjectID(groupId)
            }, (e, userHabilitations) => {
                if (e || !userHabilitations.length) return callback(e, [])

                let userGrantedOrgsAndMeshes = []   //array of arrays
                userHabilitations.forEach( hab => {
                    userGrantedOrgsAndMeshes.push(hab.grantedAccess)
                    userGrantedOrgsAndMeshes.push(hab.grantedMesh)
                })
                const userGrantedOrgsAndMeshesIDs = userGrantedOrgsAndMeshes.flat(1)

                if (!userGrantedOrgsAndMeshesIDs.length) return callback(null, [])

                global.app.SE.OrganizationalMesh.find(
                    {
                        query: {
                            $or: [
                                { "attachments": {$elemMatch : {$in : userGrantedOrgsAndMeshesIDs.map( org => global.ObjectID(org.id)) }} },
                                { _id: {$in : userGrantedOrgsAndMeshesIDs.map( org => global.ObjectID(org.id)) }}
                            ]
                        },
                        fieldPath: [],
                        group: new global.ObjectID(groupId)
                    }, (er, meshes) => {
                        if(er) return callback(er)
                        let userGrantedOrgsAndMeshesPerRole = {}
                        userHabilitations.forEach( hab => {
                            let orgsAndMeshes = []
                            orgsAndMeshes.push(hab.grantedAccess)
                            orgsAndMeshes.push(hab.grantedMesh)
                            userGrantedOrgsAndMeshesPerRole[hab.role.id] = orgsAndMeshes.flat(1).map(org => org.id)
                        })
                        if ( !!meshes.length ){
                            meshes.forEach( mesh => {
                                Object.keys(userGrantedOrgsAndMeshesPerRole)?.forEach( role => {
                                    if ( userGrantedOrgsAndMeshesPerRole[role].includes(mesh.id) ) {
                                        userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                        mesh.attachments?.forEach( att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()) )
                                    }
                                    else if ( mesh.attachments.some( orgId => userGrantedOrgsAndMeshesPerRole[role].includes(orgId.toString())) ){
                                        userGrantedOrgsAndMeshesPerRole[role].push(mesh.id)
                                        mesh.attachments?.forEach( att => userGrantedOrgsAndMeshesPerRole[role].push(att.toString()) )
                                    }
                                })
                            })
                        }
                        callback(er, userGrantedOrgsAndMeshesPerRole)
                    }
                )
            })
        }
    ], (error, results) => {
        const [userHabilitations, userGrantedOrgsAndMeshesPerRole] = results
        let auxliaryQueries = []

        auxliaryQueries.push({
            workflow: {$elemMatch: {$in: ['draft', 'preValidation', 'demand', 'control', 'validation', 'realization']}},
            delegateTo: {$elemMatch: {$eq: global.ObjectID(userId) }},
        })
        auxliaryQueries.push({
            workflow: {$elemMatch: {$in: ['draft', 'preValidation', 'demand', 'control', 'validation', 'realization']}},
            currentContributors: {$elemMatch: {$eq: global.ObjectID(userId) }},
        })

        if ( !!userHabilitations.length ){
            userHabilitations.forEach( hab => {
                if ( !hab.grantedAccess.length && !hab.grantedMesh.length ){ // exhaustif scope
                    auxliaryQueries.push( {
                        workflow: {$elemMatch: {$in: ['draft', 'preValidation', 'demand', 'control', 'validation', 'realization']}},
                        contributorsFunctions: { $elemMatch: {$eq: global.ObjectID(hab.role.id) }},
                    } )
                    auxliaryQueries.push({arbitratorsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}}})
                }
                else {
                    auxliaryQueries.push({
                        workflow: {$elemMatch: {$in: ['draft', 'preValidation', 'demand', 'control', 'validation', 'realization']}},
                        contributorsFunctions: { $elemMatch: {$eq: global.ObjectID(hab.role.id)} },
                        organizationAndMesh: { $in: userGrantedOrgsAndMeshesPerRole[hab.role.id].map(orgID => global.ObjectID(orgID)) },
                    })
                    auxliaryQueries.push({
                        arbitratorsFunctions: {$elemMatch: {$eq: global.ObjectID(hab.role.id)}},
                        organizationAndMesh: {$in: userGrantedOrgsAndMeshesPerRole[hab.role.id].map(orgID => global.ObjectID(orgID))}
                    })
                }
            })

        }

        global.app.SE.Demand.find(
            {
                query: {
                    $or: auxliaryQueries
                },
                fieldPath: ['role.id'],
                group: new global.ObjectID(groupId)
            },(error, demands) => {
                const groupedDemandsByStatus = _.groupBy(demands, demand => demand.status)
                const groupedDemandsByArbitration = groupedDemandsByStatus && Object.keys(groupedDemandsByStatus).includes('11') ? _objectWithoutProperties(groupedDemandsByStatus, ['11']) : groupedDemandsByStatus
                const noArbitrationDemands = groupedDemandsByArbitration && Object.values(groupedDemandsByArbitration).flat(1)
                const groupedDemands = !!noArbitrationDemands && !!noArbitrationDemands.length && _.groupBy(noArbitrationDemands, demand => demand.workflow[0])

                const demandsInPreValidation = [...(groupedDemands?.preValidation || []), ...(groupedDemands?.draft || [])]
                const demandsInDemand = [...(groupedDemands?.demand || []), ...(groupedDemands?.draft || [])]

                callback({
                    [userId]: {
                        "m-SE-preValidation": demandsInPreValidation.filter(demand => demand.category === "1").length,
                        "m-SE-instruction": groupedDemands?.demand ? groupedDemands.demand.filter(demand => demand.category === "1").length : 0,
                        "m-SE-control": groupedDemands?.control ? groupedDemands.control.filter(demand => demand.category === "1").length : 0,
                        "m-SE-validation": groupedDemands?.validation ? groupedDemands.validation.filter(demand => demand.category === "1").length : 0,
                        "m-SE-realization": groupedDemands?.realization ? groupedDemands.realization.filter(demand => demand.category === "1").length : 0,
                        "m-SE-arbitration": !!groupedDemandsByStatus && !!groupedDemandsByStatus['11'] ? groupedDemandsByStatus['11'].filter(demand => demand.category === "1").length : 0,
                        "m-SE-demandInstruction": demandsInDemand.filter(demand => demand.category === "2").length,
                        "m-SE-demandControl": groupedDemands?.control ? groupedDemands.control.filter(demand => demand.category === "2").length : 0,
                        "m-SE-demandValidation": groupedDemands?.validation ? groupedDemands.validation.filter(demand => demand.category === "2").length : 0,
                        "m-SE-demandArbitration": !!groupedDemandsByStatus && !!groupedDemandsByStatus['11'] ? groupedDemandsByStatus['11'].filter(demand => demand.category === "2").length : 0,
                    }
                })
            }
        )
    })
}
function _objectWithoutProperties(obj, keys) {
    var target = {};
    for (var i in obj) {
        if (keys.indexOf(i) >= 0) continue;
        if (!Object.prototype.hasOwnProperty.call(obj, i)) continue;
        target[i] = obj[i];
    }
    return target;
}


async function getFNCSNotificationData(groupId) {

    const users = await global.app.R.CUser.find({
        fieldPath: ['kpUser'],
        group: new global.ObjectID(groupId)
    })

    const controllerData = await global.app.R.Reallocation.find({
        query: {
            status: 'ongoing'
        },
        group: new global.ObjectID(groupId)
    })

    const beneficiaryData = await global.app.R.Reallocation.find({
        fieldPath: ['beneficiary.id'],
        query: {
            status: {$in: ['waitingPayment', 'rectification']}
        },
        group: new global.ObjectID(groupId)
    })

    const groupedByUser =  _.groupBy(beneficiaryData, 'beneficiary.id')


    return users.reduce((acc, cUser) => ({
        [cUser.kpUser]: {
            'm-R-reallocationRequest': groupedByUser[cUser.id] && groupedByUser[cUser.id].length,
            'm-R-reallocationValidation': !!controllerData.length && controllerData.length
        },
        ...acc
    }), {})
}

async function getCNDANotificationData(groupId) {

    const users = await global.app.C.CUser.find({
        fieldPath: ['kpUser.id'],
        group: new global.ObjectID(groupId)
    })

    const submitterFiles = await global.app.C.ReliefFund.find({
        query: {status: 'questioned'},
        fieldPath: ['submitter.id'],
        group: new global.ObjectID(groupId),
        tc: d => d
    })

    const controllerFiles = await global.app.C.ReliefFund.find({
        query: {status: {$in: ['ongoing', 'updated']}},
        fieldPath: ['id'],
        group: new global.ObjectID(groupId),
        tc: d => d
    })

    const validatorFiles = await global.app.C.ReliefFund.find({
        query: {status: 'controlled'},
        fieldPath: ['id'],
        group: new global.ObjectID(groupId),
        tc: d => d
    })

    const payerFiles = await global.app.C.ReliefFund.find({
        query: {status: 'accepted'},
        fieldPath: ['id', 'totalAwardedAmounts', 'totalValidatedAmounts'],
        group: new global.ObjectID(groupId),
        tc: d => d
    })




    return users.filter(cUser => !!_.get(cUser, 'kpUser.id')).reduce((acc, cUser) => ({
        [cUser.kpUser.id]: {
            'm-C-submission': submitterFiles.filter(file => file.submitter.id === cUser.kpUser.id).length,
            'm-C-study': controllerFiles.length,
            'm-C-decision': validatorFiles.length,
            'm-C-reliefFundPayment': payerFiles.filter(file => file.totalAwardedAmounts > file.totalValidatedAmounts).length
        },
        ...acc
    }), {})
}

async function getTicketNotificationData(userId, groupId, modelId, groupModelId) {

    const ticketSubjects = await global.app[modelId].TicketSubject.find({
        group: new global.ObjectID(groupId),
        fieldPath: ['id', 'users.id'],
        query: {}
    })

    const adminIds = ticketSubjects.flatMap(o => o.users.map(u => u.id))

    const adminIdsByTicketSubject = ticketSubjects.reduce((acc, ticketSubject) => ({
        ...acc,
        [ticketSubject.id]: ticketSubject.users.map(u => u.id)
    }), {})

    const tickets = await global.app[modelId].Ticket.find({
        query: {ticketStatus: 'inProgress'},
        fieldPath: ['id', 'name', 'user.id', 'comments.user.id', 'ticketSubject.id'],
        group: new global.ObjectID(groupId)
    })


    const userTickets = tickets.filter(ticket => {
        const lastComment = _.last(_.orderBy(ticket.comments, 'date'))
        return ticket.user && ticket.user.id !== _.get(lastComment, 'user.id')
    })

    const ticketGroupedByUser =  _.groupBy(userTickets, 'user.id')


    const lengthTicketGroupedByUser =  Object.keys(ticketGroupedByUser).reduce((acc, userId) => ({
        [userId]: ticketGroupedByUser[userId].length,
        ...acc
    }), {})


    const adminTickets = tickets.filter(ticket => {
        const lastComment = _.last(_.orderBy(ticket.comments, 'date'))
        return ticket.user && ticket.user.id === _.get(lastComment, 'user.id') && ticket.user.id !== userId
    })

    const lengthTicketGroupedByAdmin =  adminIds.reduce((acc, adminId) => {
        const filteredTicket = adminTickets.filter(
            adminTicket => adminIdsByTicketSubject[adminTicket.ticketSubject.id] && adminIdsByTicketSubject[adminTicket.ticketSubject.id].includes(adminId)
        )
        return filteredTicket.length
            ? {
                [adminId]: filteredTicket.length,
                ...acc
            }
            : acc
    }, {})

    const allUser = {...lengthTicketGroupedByUser, ...lengthTicketGroupedByAdmin}

    return Object.keys(allUser).reduce((acc, userId) => ({
        [userId]: {
            [`m-${modelId}-tickets`]: allUser[userId]
        },
        ...acc
    }), {})
}

export {
    getEdfinWorkflowNotificationData,
    getFNCSNotificationData,
    getCNDANotificationData,
    getTicketNotificationData,
    getSENotificationData
}
