//import {setFieldVisibility} from "../../../../../apps/KpModule/actions"
//import {organizationActivities} from "../../staticEntities";

export const module_ = {
    object: 'LiberalityFile',
    name: 'LiberalityDecision',
    tKey: 'Décision',
    removable: false,
    newable: false,
    objectIdentifier: 'applicationNumber',
    category: {
        path: 'Libéralités',
        icon: 'folder'
    },
    viewMap: {
        dt: [
            {path: 'applicationNumber', tKey: 'N° dossier'},
            {path: 'presentationDate', tKey: 'Présentation'},
            {path: 'administrator', display: 'fullName', tKey: 'Gestionnaire'},
            {path: 'beneficiary', tKey: 'Bénéficiaire'},
            {path: 'zipCode', tKey: "code postal"},

            {path: 'totalAmount', tKey: 'Mt net total (€)', tooltip: true},
            {path: 'netAssets', tKey: 'dont Legs (€)', tooltip: true},
            {path: 'lifeInsuranceNetAmount', tKey: 'dont A.Vie (€)', tooltip: true},

            {path: 'status', type: 'status', fieldPath: ['id', 'name', 'bStyle'], width: 350},
            {path: 'updatedAt', tKey: 'Date'},
            {path: 'updatedBy', tKey: 'Utilisateur'},
        ],
        form: {
            fields: [
                {path: 'applicationNumber', tKey: 'N° dossier', disabled: true},
                {path: 'presentationDate', tKey: 'Date de présentation'},
                {path: 'partiesSection', section: "partiesSection", data: 'Parties', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {path: 'administrator', display: 'fullName', tKey: 'Gestionnaire', filters: ['eligibleToLiberalityProcess'], disabled: true},
                {path: 'beneficiary', tKey: 'Bénéficiaire', filters: ['isAssociation'], disabled: true},
                {path: 'testator', tKey: "Testateur", disabled: true},
                {path: "testatorComment", tKey: 'Commentaire testateur', type: "richTextEditor", section: "partiesSection", disabled: true},

                {path: 'legacySection', section: "legacySection", data: 'Legs', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {path: 'object', tKey: "Objet", type: "richTextEditor", disabled: true},
                {path: 'zipCode', tKey: "code postal", section: "legacySection", disabled: true},

                {path: 'grossAssets', tKey: 'Actif brut (€)', wholePositiveNumber: true, disabled: true},
                {path: 'grossAssetsDetails', tKey: 'Détails liés à l’actif brut (€)', type: "richTextEditor", disabled: true},

                {path: 'netAssets', tKey: 'Actif net (€)', wholePositiveNumber: true, disabled: true},
                {path: 'netAssetsDetails', tKey: 'Détails liés à l’actif net (€)', type: "richTextEditor", disabled: true},

                {path: 'grossLiabilities', tKey: 'Passif brut (€)', wholePositiveNumber: true, disabled: true},
                {path: 'grossLiabilitiesDetails', tKey: 'Détails liés au passif brut (€)', type: "richTextEditor", disabled: true},

                {path: 'actsFees', tKey: 'Frais d’actes (€)', wholePositiveNumber: true, disabled: true},
                {path: 'actsFeesDetails', tKey: 'Détails liés aux frais d’actes', type: "richTextEditor", disabled: true},

                {path: 'lifeInsuranceSection', section: "lifeInsuranceSection", data: 'Assurance-vie', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {path: 'lifeInsuranceNetAmount', tKey: 'Montant net (€)', wholePositiveNumber: true, disabled: true},
                {path: 'lifeInsuranceNetAmountDetails', tKey: 'Détails liés à l’assurance-vie', type: "richTextEditor", disabled: true},

                {path: 'documents&monitoring', section: "documents&monitoring", data: 'Documents & Suivi', type: 'heading', hideLabel: true, style: {margin: '15px 5px 0', fontSize: 'larger', color: 'rgb(255 152 0)', padding: '10px 0'}},

                {path: 'files', tKeyText: "Fichiers"},
                {path: 'comments', tKeyText: "Suivi"},

                {path: 'applicationNumber', hidden: true},
                {path: 'presentationDate', hidden: true},
                {path: 'status', hidden: true},
                {path: 'buttons', hidden: true}

            ],
            onOpen: ({ store, module }) => {
                const state = store.getState()
                const statusId = _.get(state, 'edit.object.data.status.id')
                /*
                const administrativeFields = module.viewMap.form.fields.filter(field => field.section === "administrativeSection")
                const technicalFields = module.viewMap.form.fields.filter(field => field.section === "technicalSection")

                administrativeFields.forEach(field => store.dispatch(setFieldVisibility(field.id, ['filed', 'updated', 'incomplete'].includes(statusId))))
                technicalFields.forEach(field => store.dispatch(setFieldVisibility(field.id, ['preValidated', 'filed2', 'updated2', 'adjourned', 'adjournedBD'].includes(statusId))))
                 */
            }
        }
    },
    filters: [{
        path: "awaitingDecision",
        query: () => {
            return {status: 'awaitingDecision'}
        }
    }]
}
