import _ from 'lodash'
import moment from 'moment'
import { createSelector } from 'reselect'
import i18n from '../../../utils/i18nClient'
import {translateName} from '../../../utils/i18n'
import {keyInObject} from "../../../utils/utils";
import link from "react-router-dom/Link";

const hexToString = {
    '27': "'"
}

export const makeDataGetter = ({ language, t }) => field => {
    switch (field.type) {
        case 'text':
            return rowData => {
                const value = _.get(rowData, field.path)
                return field.translate ? t(value) : value
            }
        case 'date':
            return rowData => {
                const value = _.get(rowData, field.path)
                const m = moment(value)
                return m.isValid() ? m.format('YYYY-MM-DD') : value
            }
        case 'translatedText':
            return rowData =>
                translateName(_.get(rowData, field.path), language)
        case 'status':
            return rowData => {
                const object = _.get(rowData, field.path)
                if(!object) return {value: ""}
                console.log('object', object)
                console.log('display', field.display)
                const value = object[field.display]
                return {
                    bStyle: value ? object.bStyle : 'none',
                    style: object.style,
                    value: field.translateName
                        ? translateName(value, language)
                        : field.translate ? t(value) : value
                }
            }
        case 'object':
            return rowData => {
                const value = _.get(rowData, `${field.path}.${field.display}`)
                return field.translateName
                    ? translateName(value, language)
                    : field.translate ? t(value) : value
            }
        case 'objectArray':
            return rowData => {
                const value = _.get(rowData, `${field.path}`)
                return value && _.isArray(value) &&
                    _.get(rowData, `${field.path}`).map(element => {
                        const value = _.get(element, `${field.display}`)
                        return field.translateName
                            ? translateName(value, language)
                            : field.translate ? t(value) : value
                    }).join(', ')
            }
        case 'creatableTags':
            return rowData => {
                return rowData[field.path] && rowData[field.path].split(';')
            }
        case 'editText':
        case 'checkbox':
            // these fields type doesn't support dataKey with "." in the setter part
            return rowData => rowData[field.path]
        case 'fileLink':
            return rowData => _.get(rowData, `${field.path}.id`)
        case 'dateRange':
            return rowData => {
                return _.get(rowData, `${field.path}`).join(' - ')
            }
        case 'richText':
            return rowData => {
                const richText = _.get(rowData, field.path)
                const text = richText.replace(/(<([^>]+)>)/gi, "")
                return text.replace(/&#x([a-fA-F0-9]+);/g, function(match, code) {
                    return hexToString[code];
                })
            }
        case 'customized':
            return rowData => {
                return _.get(rowData, `${field.path}.data`)
            }
        case 'links':
            return rowData => {
                const links = _.get(rowData, field.path)
                return links.map(link => link.name).join(' | ')
            }
        default:
            return object => _.get(object, field.path)
    }
}

export const getUi = state => state.ui
export const getList = state => state.list
export const getListById = state => state.list.byId
export const getRandomList = state => state.randomList
export const getRandomListData = state => state.randomList.data
export const getChart = state => state.chart
export const getEdit = state => state.edit
export const getFields = state => state.fields
export const getDataLists = state => state.dataLists
export const getFilter = state => state.filter
export const getModule = state => state.module
export const getGroupModel = state => state.groupModel
export const getUser = state => state.user
export const getFormValues = state => state.form.editObject.values

export const getUserIdAndName = createSelector(
    getUser,
    user => ({
        id: user.id,
        name: user.name
    })
)

export const isUserK = createSelector(getUser, user => {
    return user ? user.k : false
})

export const isGroupModelAdmin = createSelector(getGroupModel, groupModel => {
    return groupModel.super_
})

export const isGroupModelCommunicationAdmin = createSelector(getGroupModel, groupModel => {
    return groupModel.superCom_
})

export const getModelId = createSelector(
    getModule,
    module => module.model
)

export const getModuleIsUpdatable = createSelector(
    getModule,
    module => module.updatable
)

export const getEditIsFetching = createSelector(
    getEdit,
    edit => edit.object.isFetching
)

export const getEditObjectId = createSelector(
    getEdit,
    edit => edit.object.id
)

export const getEditButtons = createSelector(getEdit, edit => edit.buttons)

export const getEditStartDateById = createSelector(getEdit, edit => edit.startDateById)
export const getEditEndDateById = createSelector(getEdit, edit => edit.endDateById)

export const getFormIcon = createSelector(
    getEdit, edit => edit.formIcon
)

export const getEditObjectData = createSelector(
    getEdit,
    edit => edit.object.data
)

export const getEditFieldsLength = createSelector(
    getEdit,
    edit => edit.fields.length
)

export const getListIsFetching = createSelector(
    getList,
    list => list.isFetching
)

export const getListIcon = createSelector(
    getList,
    list => list.listIcon
)

export const getListFetchError = createSelector(
    getList,
    list => list.error
)

export const getChartIsFetching = createSelector(
    getChart,
    graph => graph.isFetching
)

export const getChartIcon = createSelector(
    getChart,
    graph => graph.chartIcon
)

export const getChartFetchError = createSelector(
    getChart,
    graph => graph.error
)

export const getFilterMemory = createSelector(
    getFilter,
    filter => filter.memory
)

export const getFilterDataListIds = createSelector(
    getFilter,
    filter => filter.dataLists
)

export const getFilterLists = state => {
    const filter = getFilter(state)

    return filter.dataLists.map(dataListId => getDataList(state, dataListId))
}

export const getFilterListsAreFetching = state => {
    return getFilterLists(state).some(
        dataList => dataList && dataList.list && dataList.list.isFetching
    )
}

export const getFiltersFetchError = state => {
    return _(getFilterLists(state))
        .map('list.error')
        .compact()
        .head()
}

export const getEditLists = state => {
    const edit = getEdit(state)

    return edit.dataLists.map(dataListId => getDataList(state, dataListId))
}

export const getEditDataListIds = createSelector(
    getEdit,
    edit => edit.dataLists
)

export const getEditListsAreFetching = state => {
    return getEditLists(state).some(
        field => field && field.list && field.list.isFetching
    )
}

export const getEditFieldsFetchError = state => {
    return _(getEditLists(state))
        .map('list.error')
        .compact()
        .head()
}

export const getModuleIsFetching = createSelector(
    getListIsFetching,
    //getFilterListsAreFetching,
    getEditListsAreFetching,
    (...args) => args.some(arg => arg)
)

export const getModuleFetchError = createSelector(
    getListFetchError,
    getFiltersFetchError,
    getEditFieldsFetchError,
    (...args) =>
        _(args)
            .compact()
            .head()
)

export const getSortBy = createSelector(getList, list => list.sortBy)

export const getSortDirection = createSelector(
    getList,
    list => list.sortDirection
)

export const getDataTableFilter = createSelector(getList, list => list.filter)

export const getListFieldIds = createSelector(
    getList,
    list => list.fields
)

export const getListFields = createSelector(
    getListFieldIds,
    getFields,
    (listFieldIds, fields) => listFieldIds
        .filter(id => {
            const fieldExists = !!fields.byId[id]
            if(!fieldExists) console.log(`list field with id : ${id} not found in fields selector`)
            return fieldExists
        })
        .map(id => fields.byId[id])
)

export const getVisibleColumns = createSelector(
    getList,
    getGroupModel,
    (list, groupModel) => {
        const visibleColumns = localStorage.getItem('visibleColumns')

        return list.memorizeColumns && visibleColumns
            ? JSON.parse(visibleColumns)[list.accessId] || list.visibleColumns
            : list.visibleColumns
    }
)

export const getFilterFields = createSelector(
    getFilter,
    getFields,
    (filter, fields) => filter.fields.map(id => fields.byId[id])
)

export const getFiltersExist = createSelector(
    getFilterFields,
    fields => !!fields.length
)

export const getFilteredSortedIds = createSelector(
    getList,
    list => list.filteredSortedIds
)

export const getDataTableObjects = createSelector(
    getList,
    getFilteredSortedIds,
    (list, ids) => ids.map(id => list.byId[id])
)

export const getColumnSelectorOpened = createSelector(
    getList,
    list => list.columnSelectorOpened
)

export const getActionSelectorOpened = createSelector(
    getList,
    list => list.actionSelectorOpened
)

export const getFiltersOpened = createSelector(
    getList,
    list => list.filtersOpened
)

export const getChartFiltersOpened = createSelector(
    getChart,
    chart => chart.filtersOpened
)

export const getLanguage = createSelector(getUi, ui => ui.language)

export const getMode = createSelector(getUi, ui => ui.mode)

export const getObjectMode = createSelector(getUi, ui => ui.objectMode)

export const getFormUi = createSelector(getUi, ui => ui.form)

export const getEnhancedListFields = createSelector(
    getListFieldIds,
    getFields,
    getLanguage,
    getModelId,
    (listFieldIds, fields, language, modelId) => {

        const t = i18n.getFixedT(language, [modelId, 'platform'])

        const dataGetterFromField = makeDataGetter({ language, t })

        return listFieldIds
            .filter(id => {
                const fieldExists = !!fields.byId[id]
                if(!fieldExists) {
                    console.log(`field with ${id} not found`)
                }
                return fieldExists
            })
            .map(id => ({
                ...fields.byId[id],
                dataGetter: dataGetterFromField(fields.byId[id])
            })
        )
    }
)

export const getListFieldsById = createSelector(
    getEnhancedListFields,
    listFields => listFields.reduce(
        (acc, field) => Object.assign(acc, { [field.id]: field }),
        {}
    )
)

export const getDataTableColumns = createSelector(
    getEnhancedListFields,
    getVisibleColumns,
    (fields, visibleColumns) =>
        fields.filter(field => {
            return !keyInObject(visibleColumns, field.path) || visibleColumns[field.path]
        })
)

export const getColumnSelectorOptions = createSelector(
    getListFields,
    getVisibleColumns,
    (fields, visibleColumns) =>
        fields.map(field => ({
            key: field.path,
            tKey: field.tKey,
            selected: visibleColumns[field.path]
        }))
)

export const getEditFields = createSelector(
    getEdit,
    getFields,
    (edit, fields) => edit.fields.map(id => fields.byId[id])
)

export const getFieldListFields = (state, fieldId) => {
    const field = getField(state, fieldId)

    return field.listFields
        .filter(fieldId => {
            const field = getField(state, fieldId)
            return !field.hidden
        })
        .map(fieldId => {
            const field = getField(state, fieldId)
            if (field.dataList) {
                return {
                    ...field,
                    list: getDataList(state, field.dataList).list.byId
                }
            } else {
                return field
            }
        })
}

export const getFieldEditFields = (state, fieldId) => {
    const field = getField(state, fieldId)

    return field.editFields.map(fieldId => getField(state, fieldId))
}

export const getEditDefaultData = createSelector(
    getEdit,
    edit => edit.defaultObject
)

export const getField = (state, id) => {
    const fields = getFields(state)
    return fields.byId[id]
}

export const getDataList = (state, id) => {
    const dataLists = getDataLists(state)
    return dataLists.byId[id]
}


export const getDataListList = (state, id) => {  //Cette fonction prend des id et renvoie les objets correspondants. Dans byId, il y a carrement les objets classé par ID

    const dataList = getDataList(state, id)

    return dataList.list.allIds.map(id => dataList.list.byId[id])
}

export const getListOptions = (state, fieldId, sort, translate, t) => {
    const field = getField(state, fieldId)
    const dataList = getDataList(state, field.dataList)
    const options = field.options || []

    const optionsCollection = options.map(optionId => dataList.list.byId[optionId])

    const getDisplay = object => translate
        ?  t(object[field.display])
        : object[field.display]

    return sort
        ? optionsCollection.sort((a, b) => {
            if(getDisplay(a) && getDisplay(b)) {
                return getDisplay(a) && getDisplay(a).toString().localeCompare(getDisplay(b).toString())
            }
            return null
        })
        : optionsCollection

        // .sort((a, b) => {
        //     let result = !!a[field.display]
        //
        //     if(!Number.isInteger(a[field.display])) {
        //         result = a[field.display].toString().localeCompare(b[field.display].toString())
        //     } else {
        //         result = a < b
        //     }
        //     return result
        // })
}

export const getNullable = (state, fieldId) => {
    const field = getField(state, fieldId)
    return field.nullable
}

export const getFieldActive = (state, fieldId) => {
    const field = getField(state, fieldId)
    return !!field.active
}

export const getClearable = (state, fieldId) => {
    const field = getField(state, fieldId)
    return field.clearable
}
export const getfullWidth = (state, fieldId) => {
    const field = getField(state, fieldId)
    return field.fullWidth
}

export const getListOption = (state, fieldId, optionId) => {
    const field = getField(state, fieldId)
    const dataList = getDataList(state, field.dataList)

    return dataList.list.byId[optionId]
}

export const getDatePickerOpened = (state, fieldId) => {
    const field = getField(state, fieldId)
    return field.opened || false
}

export const getDateRangeStartOpened = (state, fieldId) => {
    const field = getField(state, fieldId)
    return field.startOpened || false
}

export const getDateRangeEndOpened = (state, fieldId) => {
    const field = getField(state, fieldId)
    return field.endOpened || false
}

export const getFilterObject = createSelector(
    getFilter,
    filter => filter.object
)

export const getDefaultFilterObject = state => {
    const filterMemory = getFilterMemory(state)
    return getFilterFields(state).reduce((o, field) => {
        if (field.dataList) {
            const dataList = getListOptions(state, field.id, field.sortList)

            // check if we can recover dropdown information from memory
            if (
                ['dropdownObject', 'tags'].includes(field.type) &&
                filterMemory[field.path]
                /*
                dataList.list.byId[
                    filterMemory[field.path] && filterMemory[field.path].id
                ]

                 */
            ) {
                o[field.path] = filterMemory[field.path]
            } else {

                if(field.default) {
                    if(_.isArray(field.default)) {
                        const idsDefault = field.default
                            .filter(o => o.id)
                            .map(o => o.id)
                        const defaultObjectIds = dataList
                            .filter(o => idsDefault.includes(o.id))

                        o[field.path] =  field.type === 'tags' ? defaultObjectIds : defaultObjectIds[0]
                    }
                    else {
                        o[field.path] = field.type === 'tags' ? [field.default] : field.default
                    }
                }
                else {
                    const firstListItem = dataList ? dataList[0] : null
                    o[field.path] = field.type === 'tags' ? [firstListItem] : firstListItem
                }
            }
        } else if (filterMemory[field.path]) {
            o[field.path] = filterMemory[field.path]
        } else if(field.default) {
            o[field.path] = field.default
        }
        return o
    }, {})
}
