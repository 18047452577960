import {basicContext} from "../utils/contextUtils";

const _ = require("lodash");
const moment = require("moment");
const {decrypt} = require('../utils/crypto')

const findDiff = (object, oldObject, key) => {
    if(['group'].includes(key)) {
        return false
    }

    const newValue = object[key]
    const oldValue = oldObject && oldObject[key]

    if(!oldValue) return true


    if(_.isArray(newValue)) {
        const diff1 = oldValue.some(elem => {
            const newElem = newValue.find(newElem => elem.id === newElem.id )
            if(!newElem) return true
            return Object.keys(elem).some(key => findDiff(newElem, elem, key))
        })

        const diff2 = newValue.some(elem => {
            const oldElem = oldValue.find(oldElem => oldElem.id === elem.id )
            return !oldElem
        })
        return diff1 || diff2
    }
    if(_.isObject(newValue)) {
        return newValue.id !== oldObject[key].id
    }
    return newValue != oldObject[key]
}

const getUser = () => ({
    name: "User",
    technical: true,
    collectionName: "user",
    fields: [
        "mail",
        "firstname",
        "lastname",
        "Language",
        {path: "name", fieldPath: ["firstname", "lastname"], f: function() { return this.firstname + " " + this.lastname; }},
        "phone",
        "mobilePhone",
        {path: "unsubscribed", type: "boolean"},
        {
            path: "groupModelProfiles",
            $f: function(object, context, callback) {
                const userModel = global.UserGroupModel.getByUser(context, object);
                callback(null, userModel.profiles)
            }
        },
        {
            path: 'completeInfo',
            fieldPath: [
                'groups.groupModels.profiles.id',
                'groups.groupModels.profiles.name'
            ],
            $f: function(object, context, callback) {
                const userModel = global.UserGroupModel.getByUser(context, object);
                const language = _.get(object, 'language.name')
                const translate = context.translateName || function(t) { return t}
                const profiles = userModel.profiles.map(profile => translate(profile.name)).join(', ')
                callback(null, `${object.firstname} ${object.lastname} ( ${language} - ${profiles} )`)
            }
        },
        {path: "k", type: "boolean", default: false},
        {path: "groups", type: "UserGroup", link: 'OTM'},
    ],
    filters: [
        {
            name: 'isNotCurrentContributor',
            isDefault: false,
            async: true,
            query: function(context, callback) {
                const demandOrganization = _.get(context, 'data.demandOrganization')
                const contributorsFunctions = _.get(context, 'data.contributorsFunctions')
                if (!!demandOrganization && !!demandOrganization.id && !!contributorsFunctions && !!contributorsFunctions.length){
                    return global.app.SE.OrganizationalMesh.find(
                        {
                            ...basicContext(context),
                            fieldPath: [],
                            query: {
                                $or: [
                                    {"attachments": {$elemMatch: {$eq: global.ObjectID(demandOrganization.id)}}},
                                ]
                            }
                        }, (er, meshes) => {
                            if (er) return callback(er)
                            let relatedOrgsAndMeshes = [demandOrganization]
                            if (!!meshes.length) {
                                meshes.forEach(mesh => {
                                    relatedOrgsAndMeshes.push([mesh])
                                    relatedOrgsAndMeshes.push(mesh.attachments)
                                })
                            }
                            relatedOrgsAndMeshes = relatedOrgsAndMeshes.flat(1)
                            global.app.SE.Habilitation.find(
                                {
                                    ...basicContext(context),
                                    fieldPath: ["user.id"],
                                    query: {
                                        "role": {$in: contributorsFunctions.map( role => global.ObjectID(role.id)) },
                                        $or: [{
                                            "grantedAccess": [],
                                            "grantedMesh": []
                                        }, {
                                            "grantedAccess": {$elemMatch: {$in: relatedOrgsAndMeshes}},
                                            "grantedMesh": []
                                        }, {
                                            "grantedAccess": [],
                                            "grantedMesh": {$elemMatch: {$in: relatedOrgsAndMeshes}}
                                        }]
                                    }
                                }, (er, currentContributors) => {
                                    if (er) return callback(er)
                                    const currentContributorsIDs = currentContributors.map( hab => global.ObjectID(hab.user.id) )
                                    callback(null, {_id: {$nin: currentContributorsIDs}})
                                })
                        }
                    )
                }
                return callback(null, {_id: null})
            }
        },
        {
            name: "isActive",
            isDefault: false,
            query: () => ({active: true})
        },
        {
            name: "isInGroup",
            isDefault: true,
            query: context => {
                const group = context.group;

                if (group) return {"groups.group": new global.ObjectID(group.id)}
            }
        },
        {
            name: "isInGroupModel",
            isDefault: false,
            query: context => {
                if (context.groupModel) return {
                    "groups.groupModels.groupModel": new global.ObjectID(context.groupModel.id)
                };
            }
        },
        {
            name: "isComAdmin",
            isDefault: false,
            query: context => {
                const groupModel = context.groupModel
                return groupModel
                    ? {
                        "groups.groupModels.groupModel": new global.ObjectID(groupModel.id),
                        'groups.groupModels.superCom_': true
                    }
                    : {_id: null}
            }
        },
        {
            name: "userKNotAllowed",
            isDefault: false,
            query: () => ({k: {$exists: false}})
        },
        {
            name: "notUnsubscribed",
            isDefault: false,
            query: () => ({
                $or: [
                    {unsubscribed: {$exists: false}},
                    {unsubscribed: false}
                ]
            })
        }
    ]
});


const getModule = () => ({
    name: "Module",
    type: "sync",
    fields: [
        {path: "id"},//TODO: type: "string"
        "name",
        "tKey",
        "title",
        'kp',
    ],
    db: {
        findSync: function(context) {
            return global.app.modules.map(module => {
                module = _.pick(module, ["id", "name", "tKey", "category", "title", "model_", "kp", "displayLog"])
                module.translatedTKey = context.tc(module.tKey)
                module.translatedFullName = `${context.tc(module.category?.path)} / ${context.tc(module.tKey)}`
                module.model_ = _.pick(module.model_, ["id"])
                return module
            })
        }
    },
    filters: [
        {
            name: "thisModel",
            isDefault: false,
            match: function(module, context) {
                return  module.id === 'm-T-my-account' || module.model_.id === context.model.id
            }
        },
        {
            name: "excludeMyAccountModule",
            isDefault: false,
            match: function(module) {
                return module.id !== 'm-T-my-account'
            }
        },
        {
            name: "notKp",
            isDefault: false,
            match: module => {
                return !module.kp
            }
        },
        {
            name: "hasDisplayLog",
            isDefault: false,
            match: module => {
                return module.displayLog
            }
        }
    ]
})

const getUserAction = () => ({
    name: 'UserAction',
    type: 'static',
    fields: [{path: 'id', type: 'string'}, {path: 'name', type: 'string'}],
    objects: [
        {id: 'creation', name: 'creation'},
        {id: 'modification', name: 'modification'},
        {id: 'deletion', name: 'deletion'}
    ]
})

const getSaveLog = () => ({
    name: "SaveLog",
    fields: [
        "User",
        'UserAction',
        "actionType",
        {path: "date", type: 'date', dateFormat: "YYYY-MM-DD HH:mm:ss"},
        "module",
        "changes"
    ],
    filters: [
        {
            name: 'byModule',
            path: 'module',
            isDefault: false,
            display: 'translatedFullName',
            object: 'Module',
            filters: ['thisModel', 'notKp', 'hasDisplayLog'],
            client: true,
            clearable: false
        },
        {
            name: 'byUserAction',
            path: 'action',
            isDefault: false,
            display: 'name',
            object: 'UserAction',
            client: true,
            clearable: false
        },
        {
            name: "byPeriod",
            isDefault: false,
            path: "period",
            type: "dateRange",
            client: true,
            clearable: false,
            default: [moment().subtract(1, 'day').format('YYYY-MM-DD'), moment().format("YYYY-MM-DD")],
        },
        {
            name: "byUsers",
            isDefault: false,
            display: 'name',
            path: "users",
            object: "User",
            type: 'tags',
            client: true,
            clearable: false
        }
    ],
    find: function(context, callback) {
        this.prepareContext(context, 'L', (error, context) => {
            if (error) callback(error)
            else {
                const period = _.get(context, 'data.period')
                const users = _.get(context, 'data.users')
                const actionId = _.get(context, 'data.action.id')
                const moduleId = _.get(context, 'data.module.id')

                const translate = value => {
                    return value
                        .split(',')
                        .map(value => value.trim())
                        .map(value => context.tc(value))
                        .join(' , ')
                }
                const date = new Date(period[1])
                date.setDate(date.getDate() + 1)
                const usersQuery = users && users.length > 0
                    ? {'user.id': {$in: users.map(user => user.id)}}
                    : {}
                global.db.collection('saveLog').find({
                    action: actionId,
                    date: { $gte : new Date(period[0]), $lt: date},
                    'module.id': moduleId,
                    ...usersQuery
                }).toArray((e, objects) => {
                    callback(e, objects.map(log => {
                        const model = global.models.find(model => model.id === log.model.id)
                        const module = model.modules.find(module => module.id === log.module.id)
                        const identifierField = _.concat(module.viewMap.dt.fields, module.viewMap.form.fields).find(field => field.path === module.objectIdentifier)
                        const objectIdentifier = identifierField?.entityField?.encrypted
                            ? decrypt(log.objectIdentifier)
                            : log.objectIdentifier
                        return {
                            id: log._id.toString(),
                            date: moment(log.date).format('YYYY-MM-DD HH:mm:ss'),
                            user: log.user,
                            actionType: context.tc(log.action),
                            identifier: objectIdentifier && translate(objectIdentifier),
                            module: context.tc(module.tKey)
                        }
                    }))
                })
            }
        })
    },
    get: function(id, context, callback) {
        const translate = value => {
            return value && value
                .split(',')
                .map(value => value.trim())
                .map(value => context.tc(value))
                .join(' , ')
        }
        const handleDecrypt = elem => {
            return _.isString(elem)
                ? elem.split(' ').map(elem => {
                    let result
                    try {
                        result = decrypt(elem)
                    } catch (e) {
                        console.log(e)
                        result = elem
                    }
                    return result
                }).join(' ')
                : elem
        }

        this.prepareContext(context, 'R', (error) => {
            if (error) callback(error)
            else {
                global.db.collection('saveLog').findOne({_id: global.ObjectID(id)}, (e, log) => {

                    const model = global.models.find(model => model.id === log.model.id)
                    const module = model.modules.find(module => module.id === log.module.id)

                    let changedKeys

                    if(['creation', 'modification'].includes(log.action)) changedKeys = Object.keys(log.object)

                    if(log.action === 'deletion') changedKeys = Object.keys(log.oldObject)

                    const changes = []

                    const writeChanges = (object, oldObject, key, field, translatedKey, action) => {
                        const isEncrypted = field && field.entityField.encrypted
                        const fieldDisplay = field && field.display
                        const fieldDisplayEntity = fieldDisplay && field.access?.entity?.fields?.find(entityField => entityField.path === fieldDisplay)
                        const fieldDisplayIsEncrypted = fieldDisplayEntity && fieldDisplayEntity.encrypted
                        const oldValue = oldObject && oldObject[key]
                        const newValue = object && object[key]

                        if(_.isArray(oldValue) || _.isArray(newValue)) {
                            if(['comment2', 'files2', 'accordion'].includes(field.type)) {
                                if(_.isArray(oldValue)) {
                                    oldValue.forEach( (oldObject, index) => {
                                        const object = newValue && newValue.find(object => object.id === oldObject.id )
                                        if(!object) {
                                            Object.keys(oldObject).filter(key => key !== 'id').forEach(key => {
                                                const currentField = field.type === 'accordion' && field.viewMap.form.fields.find(field => field.path === key)
                                                const translatedCurrentFieldKey = context.tc(currentField.tKey || key)
                                                const currentFieldTranslatedKey = `${translatedKey} ${index + 1}: ${translatedCurrentFieldKey}`
                                                writeChanges(object, oldObject, key, currentField, currentFieldTranslatedKey, 'deletion')
                                            })
                                        } else if (field.type === 'accordion') {
                                            Object.keys(oldObject).filter(key => key !== 'id' && findDiff(object, oldObject, key)).forEach(key => {
                                                const accordionField = field.viewMap.form.fields.find(field => field.path === key)
                                                const translatedAccordionFieldKey = context.tc(accordionField.tKey)
                                                const accordionTranslatedKey = `${translatedKey} ${index + 1}: ${translatedAccordionFieldKey}`
                                                writeChanges(object, oldObject, key, accordionField, accordionTranslatedKey, 'modification')
                                            })
                                        }
                                    })
                                }
                                if(_.isArray(newValue)) {
                                    newValue.forEach((object, index) => {
                                        const oldObject = oldValue && oldValue.find(oldObject => oldObject.id === object.id )
                                        if(!oldObject) {
                                            Object.keys(object).filter(key => key !== 'id').forEach(key => {
                                                const currentField = field.type === 'accordion' && field.views[1].fields.find(field => field.path === key)
                                                const translatedCurrentFieldKey = context.tc(currentField.tKey || key)
                                                const currentTranslatedKey = `${translatedKey} ${index + 1}: ${translatedCurrentFieldKey}`
                                                writeChanges(object, oldObject, key, currentField, currentTranslatedKey, 'creation')
                                            })
                                        }
                                    })
                                }
                            } else if(field.type === 'dateRange') {
                                changes.push({
                                    id: key,
                                    field: translatedKey,
                                    oldValue: action === 'creation' ? '---' : oldValue.map(date => moment(date).format('DD/MM/YYYY')).join(' -> '),
                                    newValue: action === 'deletion' ? '---' : newValue.map(date => moment(date).format('DD/MM/YYYY')).join(' -> ')
                                })
                            } else {
                                changes.push({
                                    id: key,
                                    field: translatedKey,
                                    oldValue: action === 'creation' ? '---' : oldValue.map(elem => {
                                        const value = elem[field.display] || elem.id
                                        return translate(fieldDisplayIsEncrypted ? handleDecrypt(elem[field.display]) : value)
                                    }).join(', '),
                                    newValue: action === 'deletion' ? '---' : newValue.map(elem => {
                                        const value = elem[field.display] || elem.id
                                        return  translate(fieldDisplayIsEncrypted ? handleDecrypt(elem[field.display]) : value)
                                    }).join(', ')
                                })
                            }
                        } else if(_.isDate(oldValue) || _.isDate(newValue)) {
                            changes.push({
                                id: key,
                                field: translatedKey,
                                oldValue: action === 'creation' ? '---' : moment(oldValue).format('DD/MM/YYYY'),
                                newValue: action === 'deletion' ? '---' : moment(newValue).format('DD/MM/YYYY')
                            })
                        } else if(_.isObject(oldValue) || _.isObject(newValue)) {
                            if(field.type === 'files2') {
                                changes.push({
                                    id: key,
                                    field: translatedKey,
                                    oldValue: action === 'creation' ? '---' : oldValue?.filename || oldValue.id,
                                    newValue: action === 'deletion' ? '---' : newValue?.filename || newValue.id
                                })
                            } else {
                                changes.push({
                                    id: key,
                                    field: translatedKey,
                                    oldValue: action === 'creation' ? '---' : oldValue && translate(fieldDisplayIsEncrypted ?  handleDecrypt(oldValue[field.display]) : (oldValue[field.display] || oldValue.id)),
                                    newValue: action === 'deletion' ? '---' : newValue && translate(fieldDisplayIsEncrypted ? handleDecrypt(newValue[field.display]): (newValue[field.display] || newValue.id))
                                })
                            }
                        } else if(typeof oldValue === 'boolean' || typeof newValue === 'boolean') {
                            changes.push({
                                id: key,
                                field: translatedKey,
                                oldValue: action === 'creation' ? '---' : oldValue ? 'OUI': 'NON',
                                newValue: action === 'deletion' ? '---' : newValue ? 'OUI': 'NON'
                            })
                        } else {
                            changes.push({
                                id: key,
                                field: translatedKey,
                                oldValue: action === 'creation' ? '---' : isEncrypted ? handleDecrypt(oldValue) : oldValue,
                                newValue: action === 'deletion' ? '---' : isEncrypted ? handleDecrypt(newValue) : newValue,
                            })
                        }
                    }
                    changedKeys
                        .filter(key => key !== 'id')
                        .filter(key => {
                            const field = module.views[1].fields.find(field => field.path === key)
                            return !!field && !['image', 'video'].includes(field.type)
                        })
                        .forEach(key => {
                            const field = module.views[1].fields.find(field => field.path === key)
                            const translatedKey = context.tc(field.tKey)
                            writeChanges(log.object, log.oldObject, key, field, translatedKey, log.action)
                        })

                    const identifierField = _.concat(module.viewMap.dt.fields, module.viewMap.form.fields).find(field => field.path === module.objectIdentifier)
                    const objectIdentifier = identifierField?.entityField?.encrypted
                        ? decrypt(log.objectIdentifier)
                        : log.objectIdentifier

                    callback(e, {
                        id,
                        date: moment(log.date).format('YYYY-MM-DD'),
                        user: log.user,
                        actionType: context.tc(log.action),
                        identifier: objectIdentifier && context.tc(objectIdentifier),
                        module: context.tc(module.tKey),
                        changes
                    })
                })
            }
        })
    }
})
const getUserGroup = () => ({
    name: "UserGroup",
    type: "mongoInternal",
    fields: [
        "Group",
        {path: "super_", type: "boolean", default: true},
        {path: "groupModels", type: "UserGroupModel", link: "OTM"},
    ]
})

const getUserGroupModel = () => ({
    name: "UserGroupModel",
    type: "mongoInternal",
    fields: [
        {path: "groupModel", type: "GroupModel"},
        {path: "super_", type: "boolean"},
        {path: "superCom_", type: "boolean"},
        {path: "profiles", type: "Profile", link: "MTM"},
    ],
    getByUser: function(context, user) {
        if(! user) user = context.user;

        const userModel = global.User.getUserModelFromGroupModel(user, context.groupModel) || {
            id: null,
            super_: null,
            superCom_: null,
            groupModel : context.groupModel,
            profiles: [],
            modules: [],
        };
        return {
            id: userModel.id,
            super_: userModel.super_,
            superCom_: userModel.superCom_,
            name: userModel.groupModel.model.name,
            groupModel: _.pick(userModel.groupModel.model, ["id", "name"]),
            profiles: userModel.profiles,
            modules: getUserModules(userModel.groupModel, userModel.profiles)
        };
    }
})

const getGroupModel = () => ({
    name: "GroupModel",
    collectionName: "groupModel",
    fields: [
        "name",
        //{type: "Model", link: "MTO"},
        {path: "fullName", fieldPath: ["model.name", "group.name"], f: function() {
                return `${this.name} (${this.group.name})`;
            }},
        {path: "modelName", fieldPath: ["model.name"], f: function() {
                return this.model.name;
            }},
        {path: "groupName", fieldPath: ["group.name"], f: function() {
                return this.group.name;
            }},
        {path: "super_", type: "boolean", lazy: true, $f: function(groupModel, context, callback) {
                const userModel = global.User.getUserModelFromGroupModel(context.user, groupModel);
                const super_ = userModel && userModel.super_;
                callback(null, super_);
            }}
    ]
})

const getProfile = () => ({
    name: "Profile",
    technical: true,
    collectionName: "profile",
    fields: [
        {path: "name"}
    ],
    filters: [
        {
            name: "thisModel",
            isDefault: true,
            query: context => {
                const groupModel = context.groupModel;
                if(groupModel) return {groupModel: new global.ObjectID(context.groupModel.id)};
            }
        }
    ]
});

const getMailData = () => ({
    name: "MailData",
    type: "static",
    technical: true,
    fields: [
        "from",
        "to",
        "replyTo",
        "html",
        "subject"
    ]
})

const getMail = () => ({
    name: "Mail",
    technical: true,
    collectionName: "mail",
    fields: [
        "to",
        "from",
        "replyTo",
        "service",
        {path: "mailData", type: "MailData"},
        {path: "sent", type: "boolean"}
    ]
});



const getMailEvent = () => ({
    name: "MailEvent",
    technical: true,
    collectionName: "mailEvent",
    fields: [
        {path: "message-id", index: true},
        "email",
        "date",
        "event",
        "subject",
        {path: "ts_event", type: "integer"},
        {path: "ts", type: "integer"},
        "reason",
        {path: "ts_epoch", type: "integer"},
        "sender_email",
        {path: "event_date", type: "date"}
    ]
});

const getLanguage = () => ({
    name: 'Language',
    type: 'static',
    fields: [
        {path: "id", type: "string"},
        {path: "name", type: "string"},
    ],
    filters: [
        {
            name: 'ByClient',
            isDefault: true,
            match: function (object) {
                return global.config.languages.includes(object.id)
            }
        }
    ],
    objects: [
        {id: "en", name: 'English'},
        {id: "es", name: 'Español'},
        {id: "fr", name: 'Français'},
        {id: "it", name: "Italiano"},
        {id: "nl", name: "Nederlands"}
    ]
});

const getGroup = () => ({
    name: "Group",
    technical: true,
    collectionName: "group",
    fields: [
        "name"
    ]
});

const getIdField = () => ({
    type: "id",
    path: "id",
    hidden: true,
    ps: { object: ["createId"] }
});

const getGroupField = () => ({
    path: "group",
    type: "Group",
    index: true
});

const groupQueryFromContext = context => {
    const groupId = _.get(context, "group.id");
    if (!groupId) console.warn("context without group");

    return {group: groupId ? new global.ObjectID(groupId) : -1};
};

// TODO use only groupQueryFromContext
const groupQueryFromContextWithoutOr = context => {
    const groupId = _.get(context, "group.id");

    if(! groupId) return {};

    return {group: new global.ObjectID(groupId)};
};


const getGroupFilter = () => ({
    name: "internal:group",
    query: groupQueryFromContext,
    isDefault: true
});


const addDefaultEntities = (entities, model) => {
    if(model.technical) return entities;

    const defaultEntities = _.filter([
        getUser(),
        getModule(),
        getUserAction(),
        getSaveLog(),
        getLanguage(),
        getProfile(),
        getMailData(),
        getMail(),
        getMailEvent(),
        getGroup(),
        getGroupModel(),
        getUserGroup(),
        getUserGroupModel()
    ], entity => ! _.find(entities, {name: entity.name}));

    return entities.concat(defaultEntities);
};

const addDefaultFields = (fields, entity) => {
    const defaultFields = _([
        getIdField(),
        (!entity.noGroup) && getGroupField()
    ])
        .compact()
        .filter(field => ! _.find(fields, {path: field.path}))
        .value();

    return [...fields, ...defaultFields];
};

const addDefaultFilters = (filters, entity) => {
    if (entity.noGroup) return filters;

    return [...filters, getGroupFilter()];
};


export {
    groupQueryFromContext,
    groupQueryFromContextWithoutOr,
    addDefaultFields,
    addDefaultEntities,
    addDefaultFilters,
    getUser,
    getLanguage
};
